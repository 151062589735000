import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetSystemSettings,
} from "@/store/main/mutations";

import { ActionContext } from "vuex";
import { AxiosError } from "axios";
import { MainState } from "@/store/main/state";
import { State } from "@/store/state";
import { SystemNotification } from "@/interfaces/system";
import { dispatchLogout } from "@/store/auth/actions";
import { getStoreAccessors } from "typesafe-vuex";
import router from "@/router";
import systemAPI from "@/api/system";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionReadSystemSettings(context: MainContext, force: boolean) {
    if (force || !context.rootState.main.systemSettings) {
      try {
        const response = await systemAPI.readSystemSettings();

        commitSetSystemSettings(context, response.data)
        return response.data;
      } catch (error) {
        await dispatchCheckAPIError(context, error);
        return null;
      }
    }
    return context.rootState.main.systemSettings;
  },

  async actionRemoveNotification(
    context: MainContext,
    payload: { notification: SystemNotification; timeout: number }
  ) {
    let timeout = 5000;

    if (typeof payload.timeout === "number") {
      timeout = payload.timeout;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, timeout);
    });
  },

  async actionCheckAPIError(context: MainContext, payload: AxiosError | any) {
    if (Object.prototype.hasOwnProperty.call(payload, 'response') && Object.prototype.hasOwnProperty.call(payload.response, 'status')) {
      if (payload.response.status === 401) {
        if (payload.response.config.url.includes("/auth/")) {
          commitAddNotification(context, {
            content: `Couldn't authenticate.\n\nDetails: ${JSON.stringify(payload.response.data?.detail)}`,
            color: "error",
          });

          await dispatchLogout(context);
        } else if (payload.response.config.url.includes("/user/me/password")) {
          commitAddNotification(context, {
            color: "error",
            content: "Couldn't validate current password.",
          });
        } else {
          commitAddNotification(context, {
            color: "error",
            content: "Permission denied.\n\nYou do not have enough privileges to perform the action.",
          });

          // TODO: ADD THIS BACK ?.
          // router.back()
        }
      } else if (payload.response.status == 403) {
        commitAddNotification(context, {
          content: "You are not authorized to perform the action.",
          color: "error",
        });
      } else if (payload.response.status == 409) {
        if (payload.response.config.url.includes("/auth/")) {
          commitAddNotification(context, {
            content: `Couldn't authenticate.\n\nDetails: ${JSON.stringify(payload.response.data?.detail)}`,
            color: "error",
          });
          router.push({ "name": "home" })
        } else {
          commitAddNotification(context, {
            content: `Couldn't perform action due to conflicts.\n\nDetails: ${JSON.stringify(payload.response.data?.detail)}`,
            color: "error",
          });
        }
      } else if (payload.response.status == 429) {
        commitAddNotification(context, {
          title: "SLOW DOWN!!!",
          icon: "mdi-alert",
          content: "You are sending too many requests. Please wait a few seconds and try again.",
          color: "warning",
        });
      } else if (![204, 201].includes(payload.response.status)) {
        commitAddNotification(context, {
          content: `Something went wrong. Please try again later.\n\nDetails: ${JSON.stringify(payload.response.data?.detail)}`,
          color: "error",
        });
      }
    } else {
      commitAddNotification(context, {
        content: `Something went wrong. Please try again later.\n\nDetails: ${JSON.stringify(payload.response?.url)}`,
        color: "error",
      });
    }
  },

};

const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchRemoveNotification = dispatch(actions.actionRemoveNotification);
export const dispatchCheckAPIError = dispatch(actions.actionCheckAPIError);
export const dispatchReadSystemSettings = dispatch(actions.actionReadSystemSettings);