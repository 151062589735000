<template>
  <ValidationObserver ref="observer" v-slot="{ invalid }">
    <v-card v-show="show" v-model="show" @click.native.stop @input.native.stop>
      <v-toolbar v-if="vtoolbar" dark dense color="primary">
        <v-btn icon dark @click="hide">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <slot name="additionalButton"></slot>
          <v-btn
            dark
            color="orange darken-1"
            depressed
            rounded
            @click="resetSubjectData"
          >
            Reset
          </v-btn>
          <v-btn
            dark
            tile
            block
            color="green darken-1"
            @click="submitSubjectAddition"
            :disabled="invalid || subjectData.subjects.length === 0"
          >
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title v-else class="text-h5">{{ title }}</v-card-title>
      <v-form>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                }"
                name="Format"
                vid="format"
              >
                <v-select
                  v-model="subjectData.input_format"
                  :items="formatItems"
                  :error-messages="errors"
                  item-text="label"
                  item-value="value"
                  label="Format"
                  type="text"
                  required
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                }"
                name="Subject Type"
                vid="subject_type"
              >
                <v-select
                  v-model="subjectData.subject_type"
                  :items="subjectMinimalTypesItems"
                  :error-messages="errors"
                  item-text="label"
                  item-value="value"
                  label="Expected Subject Type"
                  type="text"
                  required
                >
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row
            v-for="(subject, index) in subjectData.subjects"
            :key="index"
            dense
          >
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :name="'Subject ' + (Number(index) + 1).toString()"
                :vid="'subject' + (Number(index) + 1).toString()"
                :rules="validationRules(index)"
                mode="lazy"
              >
                <v-text-field
                  v-model="subjectData.subjects[index]"
                  :error-messages="errors"
                  :label="'Subject ' + (Number(index) + 1).toString()"
                  type="text"
                  required
                  @input.native.stop
                >
                  <template v-slot:append>
                    <v-icon
                      @click.prevent="removeSubject(index)"
                      color="red darken-1"
                      dark
                    >
                      mdi-minus
                    </v-icon>
                    <!-- </v-btn> -->
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-btn
              @click.prevent="addSubject"
              class="mr-1"
              icon
              dark
              color="cyan darken-1"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-form>
      <v-row v-if="!vtoolbar" dense>
        <v-col cols="1">
          <v-btn color="orange darken-1" tile block @click="resetSubjectData">
            Reset
          </v-btn>
        </v-col>
        <slot name="additionalButton"></slot>

        <v-spacer></v-spacer>
        <v-col cols="auto">
          <v-btn color="blue darken-1" tile block @click="hide"> Cancel </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="green darken-1"
            tile
            block
            @click="submitSubjectAddition"
            :disabled="invalid || subjectData.subjects.length === 0"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </ValidationObserver>
</template>
<script lang="ts">
import ComponentBase from "@/ComponentBase";
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import {
  ISubjectSubmit,
  SubjectSubmitFormatEnum,
  SubjectTypeEnum,
} from "@/interfaces/aggregation/subject";
import { IContainer } from "@/interfaces/container";
import { dispatchAddContainerSubjects } from "@/store/container/actions";

@Component
export default class ContainerSubjectAdditionForm extends Mixins(
  ComponentBase
) {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  @Prop({ type: String, default: "Add Subject into Container" })
  public readonly title!: string;

  @Prop({ type: Object, default: {}, required: true })
  public readonly containerData!: IContainer;

  @Prop({ type: String, default: "uniq_container_subject", required: false })
  public readonly subjectValidationMethod!: string;

  @Prop({ type: Boolean, default: false })
  public readonly vtoolbar!: string;

  public formatItems = [
    { label: "Plain Text", value: SubjectSubmitFormatEnum.plain },
    { label: "Host File", value: SubjectSubmitFormatEnum.host },
    { label: "AdBlock Filter List", value: SubjectSubmitFormatEnum.adblock },
  ];
  public formatSelect = this.formatItems[0];

  public subjectData: ISubjectSubmit = {
    subject_type: SubjectTypeEnum.domain,
    input_format: SubjectSubmitFormatEnum.plain,
    subjects: [],
  };

  public validationRules(index: number) {
    return {
      required: true,
      [this.subjectValidationMethod]: [
        this.subjectData.subjects[index],
        this.containerData,
      ],
    };
  }

  public addSubject() {
    this.subjectData.subjects.push("");
  }

  public removeSubject(index: number) {
    this.subjectData.subjects.splice(index, 1);
  }

  private resetSubjectData() {
    this.subjectData.input_format = SubjectSubmitFormatEnum.plain;
    this.subjectData.subjects = [];
  }

  private async submitSubjectAddition() {
    if (await this.$refs.observer.validate()) {
      await dispatchAddContainerSubjects(this.$store, {
        container: this.containerData,
        subjects: this.subjectData,
      });
      this.$emit("success", this.subjectData);
    }
  }

  @Watch("show", { immediate: true })
  private onShowChange() {
    this.resetSubjectData();
  }
}
</script>