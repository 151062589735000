// import { ActionContext } from "vuex";
// import { ExploreState } from "@/store/explore/state";
// import { State } from "@/store/state"
// import { getStoreAccessors } from "typesafe-vuex";

// type ExploreContext = ActionContext<ExploreState, State>;

export const actions = {

}

// const { dispatch } = getStoreAccessors<ExploreState | any, State>("");

