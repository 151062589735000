import "@/plugins/vee-validate/blurEager";
import "@/plugins/vee-validate/required_file_format";
import "@/plugins/vee-validate/required_test_method";
import "@/plugins/vee-validate/uniq_container_name";
import "@/plugins/vee-validate/uniq_container_subject";
import "@/plugins/vee-validate/uniq_email";
import "@/plugins/vee-validate/uniq_reserved_username";
import "@/plugins/vee-validate/uniq_role_name";
import "@/plugins/vee-validate/uniq_subject";
import "@/plugins/vee-validate/uniq_username";
import "@/plugins/vee-validate/uniq_machinename";
import "@/plugins/vee-validate/valid_ip";
import "@/plugins/vee-validate/valid_domain";
import "@/plugins/vee-validate/valid_url";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { alpha_dash, confirmed, email, max, min, required } from "vee-validate/dist/rules";

import Vue from "vue";

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

extend("confirmed", {
    ...confirmed,
    message: "{_field_} does not match",
});

extend("email", {
    ...email,
    message: "{_field_} is not a valid email address",
});

extend("min", {
    ...min,
    message: "{_field_} must have no less than {length} characters",
});

extend("max", {
    ...max,
    message: "{_field_} must have no more than {length} characters",
});

// TODO: Implement this on the server side.
extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} can only contain letters, numbers, dashes and underscores",
});



Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
