import { MachineState } from "@/store/machine/state";
import { actions } from "@/store/machine/actions";
import { getters } from "@/store/machine/getters";
import { mutations } from "@/store/machine/mutations";

const defaultState: MachineState = {
  machines: [],
  machinesTotal: 0,
}

export const machineModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters
}