import { IAllScopes, IRole, IRoleMinimal } from "@/interfaces/role";

import { RoleState } from "@/store/role/state"
import { State } from "@/store/state"
import { getStoreAccessors } from "typesafe-vuex"

export const mutations = {
    setRoles(state: RoleState, payload: IRole[]) {
        state.roles = payload;
    },
    addRole(state: RoleState, payload: IRole) {
        const roles = state.roles.filter((role: IRole) => role.id !== payload.id);
        roles.push(payload);
        state.roles = roles;
    },
    removeRole(state: RoleState, payload: IRole) {
        const roles = state.roles.filter((role: IRole) => role.id !== payload.id);
        state.roles = roles
    },
    setSearchedRoles(state: RoleState, payload: IRoleMinimal[]) {
        state.searchedRoles = payload;
    },
    addSearchedRole(state: RoleState, payload: IRoleMinimal) {
        const searchedRoles = state.searchedRoles.filter((role: IRoleMinimal) => role.id !== payload.id);
        searchedRoles.push(payload);
        state.searchedRoles = searchedRoles;
    },
    removeSearchedRole(state: RoleState, payload: IRoleMinimal) {
        const searchedRoles = state.searchedRoles.filter((role: IRoleMinimal) => role.id !== payload.id);
        state.searchedRoles = searchedRoles;
    },
    setRolesTotal(state: RoleState, payload: number) {
        state.rolesTotal = payload
    },
    setAllScopes(state: RoleState, payload: IAllScopes) {
        state.allScopes = payload;
    }
}

const { commit } = getStoreAccessors<RoleState | any, State>("");

export const commitSetRoles = commit(mutations.setRoles);
export const commitAddRole = commit(mutations.addRole);
export const commitRemoveRole = commit(mutations.removeRole);
export const commitSetSearchedRoles = commit(mutations.setSearchedRoles);
export const commitAddSearchedRole = commit(mutations.addSearchedRole);
export const commitRemoveSearchedRole = commit(mutations.removeSearchedRole);
export const commitSetRolesTotal = commit(mutations.setRolesTotal);
export const commitSetAllScopes = commit(mutations.setAllScopes);