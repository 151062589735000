var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [(_vm.vtoolbar)?_c('v-toolbar',{staticClass:"pa-0",attrs:{"dark":"","dense":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.hide}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"mr-0"},[_vm._t("additionalButton"),_c('v-btn',{attrs:{"dark":"","tile":"","block":"","color":"green darken-1","disabled":invalid},on:{"click":_vm.submitContainerCreate}},[_vm._v(" Save ")])],2)],1):_c('v-card-title',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.title))]),_c('v-form',{nativeOn:{"input":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();},"submit":function($event){$event.stopPropagation();}}},[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("Description")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":{
                required: true,
                max: 128,
                alpha_dash: true,
                uniq_container_name: [_vm.username, '_foobar_'],
              },"name":"name","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Unique Name","type":"text","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitContainerCreate.apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-chip',{attrs:{"disable":"","label":"","close-icon":"mdi-slash-forward","color":"green"}},[_vm._v(_vm._s(_vm.username))])]},proxy:true}],null,true),model:{value:(_vm.containerData.name),callback:function ($$v) {_vm.$set(_vm.containerData, "name", $$v)},expression:"containerData.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('validation-provider',{attrs:{"rules":{
                required: true,
              },"name":"display_name","vid":"display_name"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"error-messages":errors,"label":"Container Display Name","type":"text","required":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submitContainerCreate.apply(null, arguments)}},model:{value:(_vm.containerData.display_name),callback:function ($$v) {_vm.$set(_vm.containerData, "display_name", $$v)},expression:"containerData.display_name"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                required: false,
              },"name":"description","vid":"description"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"Container Description","auto-grow":"","filled":"","rows":"4"},model:{value:(_vm.containerData.description),callback:function ($$v) {_vm.$set(_vm.containerData, "description", $$v)},expression:"containerData.description"}})]}}],null,true)})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("File Generation")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                required: false,
              },"name":"Header","vid":"header"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-textarea',{attrs:{"error-messages":errors,"label":"A header to be added to any generated file.","auto-grow":"","filled":"","rows":"4"},model:{value:(_vm.containerData.head),callback:function ($$v) {_vm.$set(_vm.containerData, "head", $$v)},expression:"containerData.head"}})]}}],null,true)})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("Test Methods")]),_c('v-row',[_c('v-col',{attrs:{"cols":"4","xs":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":{
                required_test_method: ['@reputation_test', '@syntax_test'],
              },"name":"Availability Test","vid":"availability_test"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-switch',{attrs:{"error-messages":errors,"inset":"","label":"Availability Test","type":"checkbox"},model:{value:(_vm.containerData.availability_test),callback:function ($$v) {_vm.$set(_vm.containerData, "availability_test", $$v)},expression:"containerData.availability_test"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4","xs":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":{
                required_test_method: ['@availability_test', '@syntax_test'],
              },"name":"Reputation Test","vid":"reputation_test"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-switch',{attrs:{"error-messages":errors,"inset":"","label":"Reputation Test","type":"checkbox"},model:{value:(_vm.containerData.reputation_test),callback:function ($$v) {_vm.$set(_vm.containerData, "reputation_test", $$v)},expression:"containerData.reputation_test"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"4","xs":"12","sm":"6","lg":"3"}},[_c('validation-provider',{attrs:{"rules":{
                required_test_method: [
                  '@availability_test',
                  '@reputation_test',
                ],
              },"name":"Syntax Test","vid":"syntax_test"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-switch',{attrs:{"error-messages":errors,"inset":"","label":"Syntax Test","type":"checkbox"},model:{value:(_vm.containerData.syntax_test),callback:function ($$v) {_vm.$set(_vm.containerData, "syntax_test", $$v)},expression:"containerData.syntax_test"}})]}}],null,true)})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("Subject Settings")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                required: true,
              },"name":"Default Subject Type","vid":"subject_type"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"items":_vm.subjectMinimalTypesItems,"error-messages":errors,"item-text":"label","item-value":"value","inset":"","label":"Default Subject Type","type":"checkbox"},model:{value:(_vm.containerData.subject_type),callback:function ($$v) {_vm.$set(_vm.containerData, "subject_type", $$v)},expression:"containerData.subject_type"}})]}}],null,true)})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"text-h5"},[_vm._v("Visibility")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":{
                required: { allowFalse: true },
              },"name":"Public","vid":"public"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-switch',{attrs:{"error-messages":errors,"inset":"","label":_vm.containerData.public ? 'Public' : 'Private',"type":"checkbox"},model:{value:(_vm.containerData.public),callback:function ($$v) {_vm.$set(_vm.containerData, "public", $$v)},expression:"containerData.public"}})]}}],null,true)})],1)],1)],1)],1),(!_vm.vtoolbar)?_c('v-row',{attrs:{"dense":"","no-gutters":""}},[_vm._t("additionalButton"),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"blue darken-1","tile":"","block":""},on:{"click":_vm.hide}},[_vm._v(" Cancel ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"green darken-1","tile":"","block":"","disabled":invalid},on:{"click":_vm.submitContainerCreate}},[_vm._v(" Save ")])],1)],2):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }