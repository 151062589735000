<template>
  <v-card v-show="show" v-model="show">
    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <v-toolbar v-if="vtoolbar" class="pa-0" dark dense color="primary">
        <v-btn icon dark @click="hide">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items class="mr-0">
          <slot name="additionalButton"></slot>

          <v-btn
            dark
            tile
            block
            color="green darken-1"
            @click="submitContainerCreate"
            :disabled="invalid"
          >
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title v-else class="text-h4">{{ title }}</v-card-title>

      <v-form @input.native.stop @click.native.stop @submit.native.stop>
        <v-container fluid>
          <div class="text-h5">Description</div>
          <v-row>
            <v-col cols="12" lg="6">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  max: 128,
                  alpha_dash: true,
                  uniq_container_name: [username, '_foobar_'],
                }"
                name="name"
                vid="name"
              >
                <v-text-field
                  @keyup.enter="submitContainerCreate"
                  v-model="containerData.name"
                  :error-messages="errors"
                  label="Unique Name"
                  type="text"
                  required
                >
                  <template v-slot:prepend>
                    <v-chip
                      disable
                      label
                      close-icon="mdi-slash-forward"
                      color="green"
                      >{{ username }}</v-chip
                    >
                  </template>
                </v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="6">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                }"
                name="display_name"
                vid="display_name"
              >
                <v-text-field
                  @keyup.enter="submitContainerCreate"
                  v-model="containerData.display_name"
                  :error-messages="errors"
                  label="Container Display Name"
                  type="text"
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: false,
                }"
                name="description"
                vid="description"
              >
                <v-textarea
                  v-model="containerData.description"
                  :error-messages="errors"
                  label="Container Description"
                  auto-grow
                  filled
                  rows="4"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <div class="text-h5">File Generation</div>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: false,
                }"
                name="Header"
                vid="header"
              >
                <v-textarea
                  v-model="containerData.head"
                  :error-messages="errors"
                  label="A header to be added to any generated file."
                  auto-grow
                  filled
                  rows="4"
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <div class="text-h5">Test Methods</div>
          <v-row>
            <v-col cols="4" xs="12" sm="6" lg="3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required_test_method: ['@reputation_test', '@syntax_test'],
                }"
                name="Availability Test"
                vid="availability_test"
              >
                <v-switch
                  v-model="containerData.availability_test"
                  :error-messages="errors"
                  inset
                  label="Availability Test"
                  type="checkbox"
                ></v-switch>
              </validation-provider>
            </v-col>
            <v-col cols="4" xs="12" sm="6" lg="3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required_test_method: ['@availability_test', '@syntax_test'],
                }"
                name="Reputation Test"
                vid="reputation_test"
              >
                <v-switch
                  v-model="containerData.reputation_test"
                  :error-messages="errors"
                  inset
                  label="Reputation Test"
                  type="checkbox"
                ></v-switch>
              </validation-provider>
            </v-col>
            <v-col cols="4" xs="12" sm="6" lg="3">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required_test_method: [
                    '@availability_test',
                    '@reputation_test',
                  ],
                }"
                name="Syntax Test"
                vid="syntax_test"
              >
                <v-switch
                  v-model="containerData.syntax_test"
                  :error-messages="errors"
                  inset
                  label="Syntax Test"
                  type="checkbox"
                ></v-switch>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <div class="text-h5">Subject Settings</div>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: true,
                }"
                name="Default Subject Type"
                vid="subject_type"
              >
                <v-select
                  v-model="containerData.subject_type"
                  :items="subjectMinimalTypesItems"
                  :error-messages="errors"
                  item-text="label"
                  item-value="value"
                  inset
                  label="Default Subject Type"
                  type="checkbox"
                >
                </v-select>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>

        <v-container fluid>
          <div class="text-h5">Visibility</div>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="{
                  required: { allowFalse: true },
                }"
                name="Public"
                vid="public"
              >
                <v-switch
                  v-model="containerData.public"
                  :error-messages="errors"
                  inset
                  :label="containerData.public ? 'Public' : 'Private'"
                  type="checkbox"
                ></v-switch>
              </validation-provider>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-row v-if="!vtoolbar" dense no-gutters>
        <slot name="additionalButton"></slot>

        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-btn color="blue darken-1" tile block @click="hide"> Cancel </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="green darken-1"
            tile
            block
            @click="submitContainerCreate"
            :disabled="invalid"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
  </v-card>
</template>
<script lang="ts">
import ComponentBase from "@/ComponentBase";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { IContainerCreate } from "@/interfaces/container";
import { dispatchAddContainer } from "@/store/container/actions";
import { SubjectTypeEnum } from "@/interfaces/aggregation/subject";

@Component
export default class ContainerCreatorForm extends Mixins(ComponentBase) {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  @Prop({ type: String, default: "Create Container" })
  public readonly title!: string;

  @Prop({ type: Boolean, default: false })
  public readonly vtoolbar!: string;

  private containerData: IContainerCreate = {
    availability_test: false,
    description: "",
    display_name: "",
    public: true,
    reputation_test: false,
    name: "",
    syntax_test: false,
    subject_type: SubjectTypeEnum.domain,
    head: "",
  };

  public async submitContainerCreate() {
    if (await this.$refs.observer.validate()) {
      this.containerData.name = this.containerData.name.toLowerCase();

      const container = await dispatchAddContainer(
        this.$store,
        this.containerData
      );

      this.$emit("success", container);

      if (this.hideOnSuccess) {
        this.hide();
      }
    }
  }
}
</script>