import { IReservedUser, IReservedUserCreate, IReservedUserSearch } from "@/interfaces/reservedUsers";

import { apiUrl } from "@/consts";
import axios from "axios";

export const reservedUsersMetadataAPI = {}

export const reservedUsersCheckAPI = {
  ...reservedUsersMetadataAPI,

  async checkReservedUserUsername(username: string) {
    return axios.head<void>(
      `${apiUrl}/v1/system/reserved-users/username/${username}`,
    )
  }
}

export const reservedUsersAPI = {
  ...reservedUsersCheckAPI,

  async searchReservedUsers(searchQuery: IReservedUserSearch, offset = 0, limit = 100) {
    return axios.post<IReservedUser[]>(
      `${apiUrl}/v1/system/reserved-users/search?offset=${offset}&limit=${limit}`,
      searchQuery,
    )
  },

  async getReservedUsers(offset = 0, limit = 100) {
    return axios.get<IReservedUser[]>(
      `${apiUrl}/v1/system/reserved-users?offset=${offset}&limit=${limit}`,
    )
  },

  async getReservedUser(id: string) {
    return axios.get<IReservedUser>(
      `${apiUrl}/v1/system/reserved-users/${id}`,
    )
  },

  async addReservedUser(reservedUser: IReservedUserCreate) {
    return axios.post<IReservedUser>(
      `${apiUrl}/v1/system/reserved-users`,
      reservedUser,
    )
  },

  async deleteReservedUser(id: number) {
    return axios.delete<void>(
      `${apiUrl}/v1/system/reserved-users/${id}`,
    )
  }

}

export default reservedUsersAPI;