import { IAccessToken, IUserTokenRequest } from "@/interfaces/auth/token";
import { IPasswordRecoveryRequest, IPasswordResetRequest } from "@/interfaces/auth/password";
import {
  commitAddLoggedInUserToken,
  commitRemoveLoggedInUserMachine,
  commitRemoveLoggedInUserToken,
  commitSetLoggedIn,
  commitSetLoggedInUser,
  commitSetLoggedInUserContainers,
  commitSetLoggedInUserContainersTotal,
  commitSetLoggedInUserMachines,
  commitSetLoggedInUserMachinesTotal,
  commitSetLoggedInUserScopes,
  commitSetLoggedInUserTokens,
  commitSetLoginError,
  commitSetPasswordRecoveryError,
  commitSetPasswordResetError,
} from "@/store/auth/mutations";
import {
  commitAddNotification,
  commitRemoveNotification,
} from "@/store/main/mutations";
import { getIsLoggedIn, getTokenID, removeIsLoggedIn, removeToken, removeTokenID, saveIsLoggedIn, saveTokenID } from "@/utils";

import { ActionContext } from "vuex";
import { AuthState } from "@/store/auth/state";
import { IGitHubAuthorizationResponse } from "@/interfaces/auth/github";
import { ILoginCredentials } from "@/interfaces/login";
import { IMachine } from "@/interfaces/machine";
import { IUserTokenMinimal } from "@/interfaces/auth/token";
import { State } from "@/store/state";
import { api } from "@/api";
import { commitSetAllScopes } from "../role/mutations";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";
import router from "@/router";

type AuthContext = ActionContext<AuthState, State>;

export const actions = {
  actionRouteLogout() {
    if (router.currentRoute.name !== "home") {
      router.push("/");
    }
  },

  async actionUserLogout(context: AuthContext) {
    await dispatchLogout(context);
    commitAddNotification(context, {
      content: "Successfully logged out",
      color: "success",
    });
  },

  actionRouteLoggedIn() {
    // NOTE: Forces Login
    if (router.currentRoute.name !== "dashboard") {
      router.push({ name: "dashboard" });
    }
  },

  async actionLogout(context: AuthContext) {
    await dispatchRemoveLogin(context);
    await dispatchRouteLogout(context);
  },

  async actionCheckLoggedIn(context: AuthContext) {
    if (!context.state.isLoggedIn) {
      if (getIsLoggedIn() || getTokenID()) {
        try {
          const response = await api.getMe();
          commitSetLoggedIn(context, true);
          commitSetLoggedInUser(context, response.data);
        } catch (error) {
          await dispatchRemoveLogin(context);
        }
      } else {
        await dispatchRemoveLogin(context);
      }
    }
  },

  async actionRemoveLogin(context: AuthContext) {
    removeToken();
    removeTokenID();
    removeIsLoggedIn();

    commitSetLoggedIn(context, false);
    commitSetLoggedInUser(context, null);

    // TODO: Find a better way to cleanup.
    // commitSetLoginError(context, false);
    commitSetPasswordRecoveryError(context, false);
    commitSetPasswordResetError(context, false);
  },

  async actionGetCurrentUser(context: AuthContext) {
    try {
      const response = await api.getMe();

      if (response.data) {
        commitSetLoggedInUser(context, response.data);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionDeleteCurrentUser(context: AuthContext) {
    try {
      await api.deleteMe();
      await dispatchLogout(context);
      return true;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return false;
  },

  async actionCheckLoginResponse(context: AuthContext, payload: IAccessToken) {
    const tokenID = payload.jti;

    if (tokenID) {
      saveTokenID(tokenID);
      saveIsLoggedIn(true);
      commitSetLoggedIn(context, true);
      commitSetLoginError(context, false);

      await dispatchGetCurrentUser(context);
      await dispatchGetCurrentUserScopes(context);
      await dispatchRouteLoggedIn(context);

      commitAddNotification(context, {
        content: "Successfully logged in",
        color: "success",
      });
    } else {
      await dispatchLogout(context);
    }
  },

  async actionLogin(context: AuthContext, payload: ILoginCredentials) {
    try {
      const response = await api.loginGetToken(payload);

      await dispatchCheckLoginResponse(context, response.data);
    } catch (error) {
      commitSetLoginError(context, true);
      await dispatchLogout(context);
    }
  },

  async actionPasswordRecovery(context: AuthContext, payload: IPasswordRecoveryRequest) {
    const loadingNotification = {
      content: "Requesting password recovery...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.requestPasswordRecovery(payload);

      commitSetPasswordRecoveryError(context, false);
      commitRemoveNotification(context, loadingNotification);

      commitAddNotification(context, {
        content: "If the username or email address is correct, you will shortly receive an email instructing you on how to reset your password. Please be patient as it may take a few minutes for the email to get to you.",
        color: "success",
      })

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Something went wrong. Please retry.",
        color: "error",
      })
      commitSetPasswordRecoveryError(context, true);
    }
    return null
  },

  async actionPasswordReset(context: AuthContext, payload: { token: string, changeRequest: IPasswordResetRequest }) {
    const loadingNotification = {
      content: "Requesting password reset...",
      color: "info",
      showProgress: true,
    };

    try {
      const response = await api.requestPasswordReset(payload.changeRequest);

      commitSetPasswordResetError(context, false);
      commitRemoveNotification(context, loadingNotification);

      commitAddNotification(context, {
        content: "Password successfully changed.",
        color: "success"
      })

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Something went wrong. Please retry.",
        color: "error",
      })
      commitSetPasswordResetError(context, true)
    }

    return null
  },

  async actionGitHubAuthn(context: AuthContext) {
    try {
      const response = await api.getGitHubClientInfo();

      if (response.data) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGitHubLinkAuthn(context: AuthContext) {
    try {
      const response = await api.getGitHubClientLinkInfo();

      if (response.data) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGitHubUnlinkAuthn(context: AuthContext) {
    const loadingNotification = {
      content: "Unlinking your GitHub account...",
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.unlinkGitHub();

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: "GitHub account successfully unlinked.",
        color: "success"
      })
      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionGitHubAuthz(
    context: AuthContext,
    payload: IGitHubAuthorizationResponse
  ) {
    try {
      const response = await api.loginGitHubGetToken(payload);

      await dispatchCheckLoginResponse(context, response.data);
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGitLabAuthn(context: AuthContext) {
    try {
      const response = await api.getGitLabClientInfo();

      if (response.data) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGitLabLinkAuthn(context: AuthContext) {
    try {
      const response = await api.getGitLabClientLinkInfo();

      if (response.data) {
        window.location.href = response.data.authorization_url;
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGitLabUnlinkAuthn(context: AuthContext) {
    const loadingNotification = {
      content: "Unlinking your GitLab account...",
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.unlinkGitLab();

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: "GitLab account successfully unlinked.",
        color: "success"
      })
      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionGitLabAuthz(
    context: AuthContext,
    payload: IGitHubAuthorizationResponse
  ) {
    try {
      const response = await api.loginGitLabGetToken(payload);

      await dispatchCheckLoginResponse(context, response.data);
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGenerateIdentityCode(context: AuthContext) {
    const loadingNotification = {
      content: "Requesting new identity code...",
      color: "info",
      showProgress: true,
    };


    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.generateMeIdentityCode();

      commitSetLoggedInUser(context, response.data);
      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: "New identity code successfully generated.",
        color: "success"
      })

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionGetCurrentUserScopes(context: AuthContext) {
    try {
      const response = await api.getTokenInfo();

      if (response.data.scopes) {
        const formattedScopes: Record<string, boolean> = {};

        for (const scope of response.data.scopes) {
          formattedScopes[scope] = true
        }

        commitSetLoggedInUserScopes(context, formattedScopes);
      } else {
        commitSetLoggedInUserScopes(context, null);
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionGetCurrentUserTokens(context: AuthContext) {
    try {
      const response = await api.getMeTokens();

      if (response.data) {
        commitSetLoggedInUserTokens(context, response.data);
      } else {
        commitSetLoggedInUserTokens(context, []);
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionRevokeCurrentUserToken(context: AuthContext, token: IUserTokenMinimal) {
    const loadingNotification = {
      content: "Revoking token...",
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.revokeMeToken(token.id);

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: "Token successfully revoked.",
        color: "success"
      })

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionDeleteCurrentUserToken(context: AuthContext, token: IUserTokenMinimal) {
    const loadingNotification = {
      content: "Deleting token...",
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.deleteMeToken(token.id);

      commitRemoveNotification(context, loadingNotification)
      commitRemoveLoggedInUserToken(context, token)
      commitAddNotification(context, {
        content: "Token successfully deleted.",
        color: "success"
      })

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionCreateCurrentUserToken(context: AuthContext, request: IUserTokenRequest) {
    const loadingNotification = {
      content: "Creating token...",
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.createMeToken(request);

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: "Token successfully created.",
        color: "success"
      })

      commitAddLoggedInUserToken(context, { id: response.data.jti, description: request.description, revoked: false });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionGetCurrentUserScopesDescription(context: AuthContext) {
    try {
      const response = await api.getMeScopesDescription();

      if (response.data) {
        commitSetAllScopes(context, response.data);
      } else {
        commitSetAllScopes(context, {});
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionGetCurrentUserMachines(context: AuthContext, { offset = 0, limit = 100 }) {
    try {
      const response = await api.getMeMachines(offset,
        limit);

      if (response.data) {
        commitSetLoggedInUserMachines(context, response.data);
        commitSetLoggedInUserMachinesTotal(context, parseInt(response.headers["x-total"]) || response.data.length);
      } else {
        commitSetLoggedInUserMachines(context, []);
        commitSetLoggedInUserMachinesTotal(context, 0);
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionGetCurrentUserMachinesTotal(context: AuthContext) {
    try {
      const response = await api.getMeMachinesStats();

      if (response.status === 200) {
        commitSetLoggedInUserMachinesTotal(context, parseInt(response.headers["x-total"]));
      } else {
        commitSetLoggedInUserMachinesTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGenerateCurrentUserMachine(context: AuthContext) {
    try {
      const response = await api.generateMeMachine();

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionGenerateCurrentUserMachineToken(context: AuthContext, machine: IMachine) {
    try {
      const response = await api.generateMeMachineToken(machine.id);

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionDeleteCurrentUserMachine(context: AuthContext, machine: IMachine) {
    const loadingNotification = {
      content: "Deleting machine...",
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification)

      const response = await api.deleteMeMachine(machine.id);

      commitRemoveNotification(context, loadingNotification)
      commitAddNotification(context, {
        content: `Machine (@${machine.machinename}) successfully deleted.`,
        color: "success"
      })

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification)
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionDeleteMultipleCurrentUserMachine(context: AuthContext, machines: IMachine[]) {
    const loadingNotification = {
      content: `Deleting ${machines.length} subject(s) from container...`,
      color: "info",
      showProgress: true,
    };
    let errors = 0;
    let successes = 0;

    commitAddNotification(context, loadingNotification);

    for (const machine of machines) {
      try {
        await api.deleteMeMachine(
          machine.id
        );

        commitRemoveLoggedInUserMachine(context, machine)

        successes += 1;
      } catch (error) {
        errors += 1;
      }
    }

    let message = `Successfully deleted ${successes} machine(s).`

    if (errors > 0) {
      message += `\n\nFailed to delete ${errors} machine(s).`
    }

    commitRemoveNotification(context, loadingNotification);
    commitAddNotification(context, {
      content: message,
      color: errors === 0 ? "success" : "warning",
    });

    return successes > 0;
  },

  async actionCloseCurrentUserMachineScope(context: AuthContext, machine: IMachine) {
    const loadingNotification = {
      content: `Closing @${machine.machinename}'s testing scope ...`,
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification);

      const response = await api.closeMeMachineTestingScope(machine.id);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `@${machine.machinename}'s testing scope successfully closed.`,
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionOpenCurrentUserMachineScope(context: AuthContext, machine: IMachine) {
    const loadingNotification = {
      content: `Opening @${machine.machinename}'s testing scope ...`,
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification);

      const response = await api.openMeMachineTestingScope(machine.id);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `@${machine.machinename}'s testing scope successfully opened.`,
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null
  },

  async actionAllowCurrentUserMachineSupport(context: AuthContext, machine: IMachine) {
    const loadingNotification = {
      content: `Allowing @${machine.machinename} to support platform ...`,
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification);

      const response = await api.enableMeMachineTestingSupport(machine.id);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `@${machine.machinename}'s is now able to support the platform.`,
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionDisableCurrentUserMachineSupport(context: AuthContext, machine: IMachine) {
    const loadingNotification = {
      content: `Disabling @${machine.machinename}'s platform support ...`,
      color: "info",
      showProgress: true,
    };

    try {
      commitAddNotification(context, loadingNotification);

      const response = await api.disableMeMachineTestingSupport(machine.id);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `@${machine.machinename} will now focus on its defined scope.`,
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null
  },

  async actionGetCurrentUserContainers(context: AuthContext, { offset = 0, limit = 100 }) {
    try {
      const response = await api.getMeOwnedContainers(offset, limit);

      if (response.data) {
        commitSetLoggedInUserContainers(context, response.data);
        commitSetLoggedInUserContainersTotal(context, parseInt(response.headers["x-total"]) || response.data.length);
      } else {
        commitSetLoggedInUserContainers(context, []);
        commitSetLoggedInUserContainersTotal(context, 0);
      }

      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionGetCurrentUserContainersTotal(context: AuthContext) {
    try {
      const response = await api.getMeContainersStats();

      if (response.status === 200) {
        commitSetLoggedInUserContainersTotal(context, parseInt(response.headers["x-total"]));
      } else {
        commitSetLoggedInUserContainersTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  }
};

const { dispatch } = getStoreAccessors<AuthState | any, State>("");

export const dispatchLogin = dispatch(actions.actionLogin);
export const dispatchPasswordRecovery = dispatch(actions.actionPasswordRecovery);
export const dispatchPasswordReset = dispatch(actions.actionPasswordReset);

export const dispatchGitHubAuthn = dispatch(actions.actionGitHubAuthn);
export const dispatchGitHubLinkAuthn = dispatch(actions.actionGitHubLinkAuthn);
export const dispatchGitHubUnlinkAuthn = dispatch(actions.actionGitHubUnlinkAuthn);
export const dispatchGitHubAuthz = dispatch(actions.actionGitHubAuthz);

export const dispatchGitLabAuthn = dispatch(actions.actionGitLabAuthn);
export const dispatchGitLabLinkAuthn = dispatch(actions.actionGitLabLinkAuthn);
export const dispatchGitLabUnlinkAuthn = dispatch(actions.actionGitLabUnlinkAuthn);
export const dispatchGitLabAuthz = dispatch(actions.actionGitLabAuthz);


export const dispatchRemoveLogin = dispatch(actions.actionRemoveLogin);
export const dispatchRouteLogout = dispatch(actions.actionRouteLogout);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchLogout = dispatch(actions.actionLogout);
export const dispatchUserLogout = dispatch(actions.actionUserLogout);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchCheckLoginResponse = dispatch(
  actions.actionCheckLoginResponse
);

export const dispatchGenerateIdentityCode = dispatch(actions.actionGenerateIdentityCode);

export const dispatchGetCurrentUser = dispatch(actions.actionGetCurrentUser);
export const dispatchDeleteCurrentUser = dispatch(actions.actionDeleteCurrentUser);
export const dispatchGetCurrentUserTokens = dispatch(actions.actionGetCurrentUserTokens);
export const dispatchRevokeCurrentUserToken = dispatch(actions.actionRevokeCurrentUserToken);
export const dispatchDeleteCurrentUserToken = dispatch(actions.actionDeleteCurrentUserToken);
export const dispatchCreateCurrentUserToken = dispatch(actions.actionCreateCurrentUserToken);
export const dispatchGetCurrentUserScopesDescription = dispatch(actions.actionGetCurrentUserScopesDescription);

export const dispatchGetCurrentUserMachines = dispatch(actions.actionGetCurrentUserMachines);
export const dispatchGetCurrentUserMachinesTotal = dispatch(actions.actionGetCurrentUserMachinesTotal);
export const dispatchDeleteCurrentUserMachine = dispatch(actions.actionDeleteCurrentUserMachine);
export const dispatchDeleteMultipleCurrentUserMachine = dispatch(actions.actionDeleteMultipleCurrentUserMachine);
export const dispatchGenerateCurrentUserMachine = dispatch(actions.actionGenerateCurrentUserMachine);
export const dispatchGenerateCurrentUserMachineToken = dispatch(actions.actionGenerateCurrentUserMachineToken);
export const dispatchCloseCurrentUserMachineScope = dispatch(actions.actionCloseCurrentUserMachineScope);
export const dispatchOpenCurrentUserMachineScope = dispatch(actions.actionOpenCurrentUserMachineScope);
export const dispatchAllowCurrentUserMachineSupport = dispatch(actions.actionAllowCurrentUserMachineSupport);
export const dispatchDisableCurrentUserMachineSupport = dispatch(actions.actionDisableCurrentUserMachineSupport);


export const dispatchGetCurrentUserContainers = dispatch(actions.actionGetCurrentUserContainers);
export const dispatchGetCurrentUserContainersTotal = dispatch(actions.actionGetCurrentUserContainersTotal);

export const dispatchGetCurrentUserScopes = dispatch(actions.actionGetCurrentUserScopes);
