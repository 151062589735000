const settingsRoutes = {
  path: "/settings",
  component: () =>
    import(/* webpackChunkName: "settings" */ "../views/settings/StartView.vue"),
  children: [
    {
      path: "",
      name: "settings",
      component: () =>
        import(
          /* webpackChunkName: "settings-home" */ "../views/settings/UserSettingsView.vue"
        ),
    },
    {
      path: "me",
      name: "me-settings",
      component: () =>
        import(
          /* webpackChunkName: "meuser-settings-home" */ "../views/settings/UserSettingsView.vue"
        ),
    },
  ]
}

export default settingsRoutes