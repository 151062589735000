import { ReservedUserState } from "@/store/reservedUser/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
    reservedUsers: (state: ReservedUserState) => state.reservedUsers,
    reservedUsersTotal: (state: ReservedUserState) => state.reservedUsersTotal,
}

const { read } = getStoreAccessors<ReservedUserState, State>("");

export const readReservedUsers = read(getters.reservedUsers);
export const readReservedUsersTotal = read(getters.reservedUsersTotal);
