import { ReservedUserState } from "@/store/reservedUser/state"
import { actions } from "@/store/reservedUser/actions"
import { getters } from "@/store/reservedUser/getters"
import { mutations } from "@/store/reservedUser/mutations"

const defaultState: ReservedUserState = {
    reservedUsers: [],
    reservedUsersTotal: 0
}

export const reservedUserModule = {
    // namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters
}