const authRoutes = {
  path: "/auth",
  name: "auth",
  component: () =>
    import(/* webpackChunkName: "auth" */ "../views/auth/StartView.vue"),
  children: [
    {
      path: "login",
      name: "login-auth",
      component: () =>
        import(
            /* webpackChunkName: "login-auth" */ "../views/auth/LoginView.vue"
        ),

      children: [
      ],
    },
    {
      path: "github",
      name: "github-auth",
      component: () =>
        import(
            /* webpackChunkName: "github-auth" */ "../views/auth/github/GitHubView.vue"
        ),

      children: [
        {
          path: "callback",
          name: "github-auth-callback",
          component: () =>
            import(
                /* webpackChunkName: "github-auth-callback" */ "../views/auth/github/GitHubCallbackView.vue"
            ),
        },
      ],
    },
    {
      path: "gitlab",
      name: "gitlab-auth",
      component: () =>
        import(
            /* webpackChunkName: "gitlab-auth" */ "../views/auth/gitlab/GitLabView.vue"
        ),

      children: [
        {
          path: "callback",
          name: "gitlab-auth-callback",
          component: () =>
            import(
                /* webpackChunkName: "gitlab-auth-callback" */ "../views/auth/gitlab/GitLabCallbackView.vue"
            ),
        },
      ],
    },
    {
      path: "link",
      name: "link-auth",
      component: () =>
        import(
            /* webpackChunkName: "link-auth" */ "../views/auth/StartView.vue"
        ),

      children: [
        {
          path: "github",
          name: "link-auth-github",
          component: () =>
            import(
                /* webpackChunkName: "link-auth-github" */ "../views/auth/github/GitHubLinkView.vue"
            ),
        },
        {
          path: "gitlab",
          name: "link-auth-gitlab",
          component: () =>
            import(
                /* webpackChunkName: "link-auth-gitlab" */ "../views/auth/gitlab/GitLabLinkView.vue"
            ),
        },
      ],
    },
    {
      path: "password",
      name: "password-auth",
      component: () =>
        import(
            /* webpackChunkName: "password-auth" */ "../views/auth/StartView.vue"
        ),

      children: [
        {
          path: "recovery",
          name: "password-auth-recovery",
          component: () =>
            import(
                /* webpackChunkName: "password-auth-recovery" */ "../views/auth/password/PasswordRecoveryView.vue"
            ),
        },
        {
          path: "reset",
          name: "password-auth-reset",
          component: () =>
            import(
                /* webpackChunkName: "password-auth-reset" */ "../views/auth/password/PasswordResetView.vue"
            ),
        }
      ]
    }
  ],
}

export default authRoutes;