import { IAllScopes, IRole, IRoleCreate, IRoleMinimal, IRoleScopes, IRoleSearch, IRoleUpdate } from "@/interfaces/role";

import { apiUrl } from "@/consts";
import axios from "axios";

export const rolesMetadataAPI = {
  async getAllScopes() {
    return axios.get<IAllScopes>(
      `${apiUrl}/v1/role/scopes`,
    );
  },
}

export const rolesCheckAPI = {
  ...rolesMetadataAPI,

  async checkRoleName(name: string) {
    return axios.head<void>(
      `${apiUrl}/v1/role/name/${name}`,
    )
  }
}

export const rolesAPI = {
  ...rolesCheckAPI,


  async searchRoles(searchQuery: IRoleSearch, offset = 0, limit = 100) {

    return axios.post<IRoleMinimal[]>(
      `${apiUrl}/v1/role/search?offset=${offset}&limit=${limit}`,
      searchQuery,
    )
  },

  async getRoles(offset = 0, limit = 100) {
    return axios.get<IRole[]>(
      `${apiUrl}/v1/role?offset=${offset}&limit=${limit}`,
    )
  },

  async getRole(id: string) {
    return axios.get<IRole>(
      `${apiUrl}/v1/role/${id}`,
    )
  },

  async getRoleScopes(id: string) {
    return axios.get<IRoleScopes>(
      `${apiUrl}/v1/role/${id}/scopes`,
    )
  },

  async addRole(role: IRoleCreate) {
    return axios.post<IRole>(`${apiUrl}/v1/role`, role,)
  },

  async updateRole(id: string, role: IRoleUpdate) {
    return axios.patch<IRole>(`${apiUrl}/v1/role/${id}`, role,)
  },

  async updateRoleScopes(id: string, scopes: IRoleScopes) {
    return axios.patch<IRoleScopes>(`${apiUrl}/v1/role/${id}/scopes`,
      scopes,
    )
  },

  async deleteRole(id: string) {
    return axios.delete<void>(`${apiUrl}/v1/role/${id}`,)
  }
}

export default rolesAPI;