import { RoleState } from "@/store/role/state";
import { actions } from "@/store/role/actions";
import { getters } from "@/store/role/getters";
import { mutations } from "@/store/role/mutations";

const defaultState: RoleState = {
  roles: [],
  searchedRoles: [],
  rolesTotal: 0,
  allScopes: {},
}

export const roleModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters
}