<template>
  <center>
    <h1>Not Authorized!</h1>
  </center>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import AppBase from "@/AppBase";

@Component({
  components: {},
  metaInfo: {
    title: "Not Authorized",
  },
})
export default class NotAuthorizedView extends Mixins(AppBase) {}
</script>
