const dashboardRoutes = {
  path: "/dashboard",
  component: () =>
    import(
            /* webpackChunkName: "dashboard-start" */ "../views/dashboard/StartView.vue"
    ),
  children: [
    {
      path: "",
      name: "dashboard",
      component: () =>
        import(
                /* webpackChunkName: "dashboard-home" */ "../views/dashboard/HomeView.vue"
        )
    },
    {
      path: "me",
      name: "dashboard-me",
      component: () =>
        import(
                /* webpackChunkName: "dashboard-me" */ "../views/dashboard/me/StartView.vue"
        ),
      children: [
        {
          path: "machines",
          name: "dashboard-me-machines",
          component: () =>
            import(
                    /* webpackChunkName: "dashboard-me-machines" */ "../views/dashboard/me/MachinesView.vue"
            ),
        },
        {
          path: "containers",
          name: "dashboard-me-containers",
          component: () =>
            import(
                    /* webpackChunkName: "dashboard-me-machines" */ "../views/dashboard/me/ContainersView.vue"
            ),
        }
      ]
    }
  ],
}

export default dashboardRoutes;