import { IMachine } from "@/interfaces/machine";
import { apiUrl } from "@/consts";
import axios from "axios";

export const machineMetadataAPI = {
    async getMachineStats() {
        return axios.head<null>(
            `${apiUrl}/v1/machine`,
        );
    },
};

export const machineCheckAPI = {
    async checkMachineName(name: string) {
        return axios.head<null>(
            `${apiUrl}/v1/machine/machinename/${name}`,
        );
    },
};

export const machineAPI = {
    ...machineMetadataAPI,
    ...machineCheckAPI,

    async getMachines(offset = 0, limit = 100) {
        return axios.get<Array<IMachine>>(
            `${apiUrl}/v1/machine?offset=${offset}&limit=${limit}`,
        );
    },

    async getMachine(id: string) {
        return axios.get<IMachine>(
            `${apiUrl}/v1/machine/${id}`,
        );
    },

    async deleteMachine(machineID: string) {
        return axios.delete<void>(
            `${apiUrl}/v1/machine/${machineID}`,
        );
    },

};

export default machineAPI;