import { MainState } from "@/store/main/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
  miniDrawer: (state: MainState) => state.miniDrawer,
  showDrawer: (state: MainState) => state.showDrawer,
  showAppBar: (state: MainState) => state.showAppBar,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 ? state.notifications[0] : null,
  showNotFound: (state: MainState) => state.showNotFound,
  showNotAuthorized: (state: MainState) => state.showNotAuthorized,
  systemSettings: (state: MainState) => state.systemSettings,
  containerFillHeight: (state: MainState) => state.displayContainerFillHeight,
  showNewContainer: (state: MainState) => state.showNewContainer,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readMiniDrawer = read(getters.miniDrawer);
export const readShowDrawer = read(getters.showDrawer);
export const readShowAppBar = read(getters.showAppBar);
export const readFirstNotification = read(getters.firstNotification);
export const readShowNotFound = read(getters.showNotFound);
export const readShowNotAuthorized = read(getters.showNotAuthorized);
export const readSystemSettings = read(getters.systemSettings);
export const readDisplayContainerFillHeight = read(getters.containerFillHeight);
export const readShowNewContainer = read(getters.showNewContainer);