import { IAccessToken, IAccessTokenPayload } from "@/interfaces/auth/token";
import {
  IGitHubAuthorizationResponse,
  IGitHubClientInfo,
} from "@/interfaces/auth/github";
import { IPasswordRecoveryRequest, IPasswordResetRequest } from "../interfaces/auth/password";

import { IAPIMessage } from "../interfaces/message";
import { ILoginCredentials } from "@/interfaces/login";
import { IUser } from "@/interfaces/user";
import { apiUrl } from "@/consts";
import axios from "axios";

export const authAPI = {
  async loginGetToken(credentials: ILoginCredentials) {
    const params = new URLSearchParams();

    params.append("username", credentials.username);
    params.append("password", credentials.password);

    return axios.post<IAccessToken>(`${apiUrl}/v1/auth/token`, params);
  },

  async getTokenInfo() {
    return axios.get<IAccessTokenPayload>(`${apiUrl}/v1/auth/token`);
  },

  async getGitHubClientInfo() {
    return axios.get<IGitHubClientInfo>(`${apiUrl}/v1/auth/github/client`);
  },

  async getGitHubClientLinkInfo() {
    return axios.get<IGitHubClientInfo>(`${apiUrl}/v1/auth/github/link`,);
  },

  async unlinkGitHub() {
    return axios.put<IUser>(`${apiUrl}/v1/auth/github/unlink`, {},)
  },

  async loginGitHubGetToken(authorization: IGitHubAuthorizationResponse) {
    return axios.post<IAccessToken>(
      `${apiUrl}/v1/auth/github/authorize`,
      authorization
    );
  },

  async getGitLabClientInfo() {
    return axios.get<IGitHubClientInfo>(`${apiUrl}/v1/auth/gitlab/client`);
  },

  async getGitLabClientLinkInfo() {
    return axios.get<IGitHubClientInfo>(`${apiUrl}/v1/auth/gitlab/link`,);
  },

  async unlinkGitLab() {
    return axios.put<IUser>(`${apiUrl}/v1/auth/gitlab/unlink`, {},)
  },

  async loginGitLabGetToken(authorization: IGitHubAuthorizationResponse) {
    return axios.post<IAccessToken>(
      `${apiUrl}/v1/auth/gitlab/authorize`,
      authorization
    );
  },

  async requestPasswordRecovery(request: IPasswordRecoveryRequest) {
    return axios.post<IAPIMessage>(
      `${apiUrl}/v1/auth/password/recovery`,
      request
    )
  },

  async requestPasswordReset(request: IPasswordResetRequest) {
    return axios.post<IUser>(
      `${apiUrl}/v1/auth/password/reset`,
      request,
    )
  },

  async verifyPasswordResetToken(token: string) {
    return axios.head<null>(`${apiUrl}/v1/auth/password/reset`, { "headers": { "Authorization": token } })
  }
};

export default authAPI;