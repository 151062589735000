import { ContainerState } from "@/store/container/state"
import { State } from "@/store/state"
import { getStoreAccessors } from "typesafe-vuex"

export const getters = {
    containers: (state: ContainerState) => state.containers,
    containersTotal: (state: ContainerState) => state.containersTotal,

    lContainer: (state: ContainerState) => state.lContainer,

    lContainerExportRules: (state: ContainerState) => state.lContainerExportRules,
    lContainerExportRulesTotal: (state: ContainerState) => state.lContainerExportRulesTotal,

    lContainerRemoteSubjects: (state: ContainerState) => state.lContainerRemoteSubjects,
    lContainerRemoteSubjectsTotal: (state: ContainerState) => state.lContainerRemoteSubjectsTotal,

    lContainerAssociatedSubjects: (state: ContainerState) => state.lContainerAssociatedSubjects,
    lContainerAssociatedSubjectsTotal: (state: ContainerState) => state.lContainerAssociatedSubjectsTotal,

    lContainerIgnoredSubjects: (state: ContainerState) => state.lContainerIgnoredSubjects,
    lContainerIgnoredSubjectsTotal: (state: ContainerState) => state.lContainerIgnoredSubjectsTotal,

    lContainerStashedSubjects: (state: ContainerState) => state.lContainerStashedSubjects,
    lContainerStashedSubjectsTotal: (state: ContainerState) => state.lContainerStashedSubjectsTotal,

}

const { read } = getStoreAccessors<ContainerState, State>("");

export const readContainers = read(getters.containers);
export const readContainersTotal = read(getters.containersTotal);

export const readLContainer = read(getters.lContainer);

export const readLContainerExportRules = read(getters.lContainerExportRules);
export const readLContainerExportRulesTotal = read(getters.lContainerExportRulesTotal);

export const readLContainerRemoteSubjects = read(getters.lContainerRemoteSubjects);
export const readLContainerRemoteSubjectsTotal = read(getters.lContainerRemoteSubjectsTotal);

export const readLContainerAssociatedSubjects = read(getters.lContainerAssociatedSubjects);
export const readLContainerAssociatedSubjectsTotal = read(getters.lContainerAssociatedSubjectsTotal);

export const readLContainerIgnoredSubjects = read(getters.lContainerIgnoredSubjects);
export const readLContainerIgnoredSubjectsTotal = read(getters.lContainerIgnoredSubjectsTotal);

export const readLContainerStashedSubjects = read(getters.lContainerStashedSubjects);
export const readLContainerStashedSubjectsTotal = read(getters.lContainerStashedSubjectsTotal);
