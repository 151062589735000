import {
  IUser,
  IUserCreate,
  IUserScopes,
  IUserSearch,
  IUserUpdate,
} from "@/interfaces/user";

import { IContainer } from "@/interfaces/container";
import { IRoleMinimal } from "@/interfaces/role";
import { apiUrl } from "@/consts";
import axios from "axios";
import { hasScope } from "@/utils/user";

export const userMetadataAPI = {
  async getUserStats() {
    return axios.head<null>(
      `${apiUrl}/v1/user`,
    );
  },
};

export const userCheckAPI = {
  async checkUserUsername(username: string) {
    return axios.head<null>(
      `${apiUrl}/v1/hub/user/username/${username}`,
    );
  },

  async checkUserEmail(email: string) {
    return axios.head<null>(
      `${apiUrl}/v1/user/email/${email}`,
    );
  },


};

export const userAPI = {
  ...userMetadataAPI,
  ...userCheckAPI,

  async searchUsers(searchQuery: IUserSearch, offset = 0, limit = 100) {
    if (hasScope("user:read")) {
      return axios.post<IUser[]>(`${apiUrl}/v1/user/search?offset=${offset}&limit=${limit}`, searchQuery)
    }
    return axios.post<IUser[]>(`${apiUrl}/v1/hub/user/search?offset=${offset}&limit=${limit}`, searchQuery)
  },

  async getUsers(offset = 0, limit = 100) {
    return axios.get<IUser[]>(
      `${apiUrl}/v1/user?offset=${offset}&limit=${limit}`,
    );
  },

  async getUser(username: string) {
    if (hasScope("user:read")) {
      return axios.get<IUser>(
        `${apiUrl}/v1/user/${username}`,
      );
    }
    return axios.get<IUser>(
      `${apiUrl}/v1/hub/user/${username}`,
    );
  },

  async getUserOwnedContainers(username: string) {
    if (hasScope("user:read")) {
      return axios.get<IContainer[]>(
        `${apiUrl}/v1/user/${username}/containers/self`,
      );
    }
    return axios.get<IContainer[]>(
      `${apiUrl}/v1/hub/user/${username}/containers/self`,
    )
  },

  async getUserContributedContainers(username: string) {
    if (hasScope("user:read")) {
      return axios.get<IContainer[]>(
        `${apiUrl}/v1/user/${username}/containers/contributed`,
      );
    }
    return axios.get<IContainer[]>(
      `${apiUrl}/v1/hub/user/${username}/containers/contributed`,
    )
  },

  async updateUser(username: string, user: IUserUpdate) {
    return axios.patch<IUser>(
      `${apiUrl}/v1/user/${username}`,
      user,
    );
  },

  async updateUserScopes(username: string, scopes: Record<string, unknown>) {
    return axios.patch<IUserScopes>(
      `${apiUrl}/v1/user/${username}/scopes`,
      scopes,
    );
  },

  async assignUserRole(username: string, roleID: string) {
    return axios.put<IRoleMinimal>(
      `${apiUrl}/v1/user/${username}/roles/${roleID}`,
      {},
    )
  },

  async revokeUserRole(username: string, roleID: string) {
    return axios.delete<void>(
      `${apiUrl}/v1/user/${username}/roles/${roleID}`,
    )
  },

  async deleteUser(username: string) {
    return axios.delete<void>(
      `${apiUrl}/v1/user/${username}`,
    );
  },

  async addUser(user: IUserCreate) {
    return axios.post<IUser>(
      `${apiUrl}/v1/user`,
      user,
    );
  },
};

export default userAPI;