import Vue from "vue";

Vue.directive("uppercase", {
    componentUpdated: function (el: HTMLInputElement) {
        el.value = el.value.toUpperCase()
    }
});

Vue.directive("lowercase", {
    componentUpdated: function (el: HTMLInputElement) {
        el.value = el.value.toLowerCase()
    }
})
