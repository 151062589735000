import { RoleState } from "@/store/role/state"
import { State } from "@/store/state"
import { getStoreAccessors } from "typesafe-vuex"

export const getters = {
    roles: (state: RoleState) => state.roles,
    searchedRoles: (state: RoleState) => state.searchedRoles,
    rolesTotal: (state: RoleState) => state.rolesTotal,
    allScopes: (state: RoleState) => state.allScopes,
}

const { read } = getStoreAccessors<RoleState, State>("");

export const readRoles = read(getters.roles);
export const readSearchedRoles = read(getters.searchedRoles);
export const readRolesTotal = read(getters.rolesTotal);
export const readAllScopes = read(getters.allScopes);
