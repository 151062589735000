import { IRole, IRoleCreate, IRoleMinimal, IRoleScopes, IRoleSearch, IRoleUpdate } from "@/interfaces/role";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import { commitRemoveRole, commitSetAllScopes, commitSetRoles, commitSetRolesTotal, commitSetSearchedRoles } from "./mutations";

import { ActionContext } from "vuex";
import { AxiosResponse } from "axios";
import { RoleState } from "@/store/role/state";
import { State } from "@/store/state";
import { SystemNotification } from "@/interfaces/system";
import api from "@/api";
import { dispatchCheckAPIError } from "../main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type RoleContext = ActionContext<RoleState, State>;

export const actions = {
  async actionSearchRoles(context: RoleContext, { searchQuery = "", approximate = false, offset = 0, limit = -1 }) {
    const query: IRoleSearch = {
      approximate: approximate,
      name: searchQuery
    }

    try {
      const response: AxiosResponse<IRoleMinimal[], any> = await api.searchRoles(
        query,
        offset,
        limit
      )

      if (response.data) {
        commitSetSearchedRoles(context, response.data);
      } else {
        commitSetSearchedRoles(context, []);
      }
      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return []
  },

  async actionGetRoles(context: RoleContext, { offset = 0, limit = 0 }) {
    try {
      const response = await api.getRoles(
        offset, limit
      )

      if (response.data) {
        commitSetRoles(context, response.data);
        commitSetRolesTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))
      } else {
        commitSetRoles(context, []);
        commitSetRolesTotal(context, 0);
      }
      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionAddRole(context: RoleContext, role: IRoleCreate) {
    const loadingNotification: SystemNotification = {
      content: "Adding role ...",
      color: "info",
      showProgress: true
    }

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addRole(role);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully created the '${role.name}' role.`,
        color: "success"
      })

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionUpdateRole(
    context: RoleContext,
    info: { role: IRole, updated: IRoleUpdate }
  ) {
    const loadingNotification: SystemNotification = {
      content: "Updating role ...",
      color: "info",
      showProgress: true
    }

    commitAddNotification(context, loadingNotification)

    try {
      const response = await api.updateRole(
        info.role.id,
        info.updated
      )

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated the '${info.role.name}' role.`,
        color: "success",
      })

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error)
    }
  },

  async actionDeleteRole(context: RoleContext, role: IRole) {
    const loadingNotification = {
      content: "Deleting role...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await Promise.all([
        api.deleteRole(role.id),
        await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
      ])

      commitRemoveNotification(context, loadingNotification);
      await commitRemoveRole(context, role);
      commitAddNotification(context, {
        content: `Successfully deleted the '${role.name} role.`,
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetAllScopes(context: RoleContext) {
    try {
      const response = await api.getAllScopes(
      );

      if (response.data) {
        commitSetAllScopes(context, response.data);
      } else {
        commitSetAllScopes(context, {});
      }
      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionUpdateRoleScopes(context: RoleContext, info: { role: IRole, scopes: IRoleScopes }) {
    const loadingNotification = {
      content: "Updating role scopes...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateRoleScopes(
        info.role.id,
        info.scopes
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated the scopes of the '${info.role.name}' role.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null
  }
}

const { dispatch } = getStoreAccessors<RoleState | any, State>("");

export const dispatchSearchRoles = dispatch(actions.actionSearchRoles);
export const dispatchGetRoles = dispatch(actions.actionGetRoles);
export const dispatchAddRole = dispatch(actions.actionAddRole);
export const dispatchUpdateRole = dispatch(actions.actionUpdateRole);
export const dispatchDeleteRole = dispatch(actions.actionDeleteRole);
export const dispatchGetAllScopes = dispatch(actions.actionGetAllScopes);
export const dispatchUpdateRoleScopes = dispatch(actions.actionUpdateRoleScopes);
