import { ISystemSettings, SystemNotification } from "@/interfaces/system";

import { MainState } from "@/store/main/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const mutations = {
  setMiniDrawer(state: MainState, payload: boolean) {
    state.miniDrawer = payload;
  },
  setShowDrawer(state: MainState, payload: boolean) {
    state.showDrawer = payload;
  },
  setShowAppBar(state: MainState, payload: boolean) {
    state.showAppBar = payload;
  },
  addNotification(state: MainState, payload: SystemNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: SystemNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification != payload
    );
  },
  setShowNotFound(state: MainState, payload: boolean) {
    state.showNotFound = payload;
  },
  setShowNotAuthorized(state: MainState, payload: boolean) {
    state.showNotAuthorized = payload;
  },
  setSystemSettings(state: MainState, payload: ISystemSettings) {
    state.systemSettings = payload;
  },
  setDisplayContainerFillHeight(state: MainState, payload: boolean) {
    state.displayContainerFillHeight = payload;
  },
  setShowNewContainer(state: MainState, payload: boolean) {
    state.showNewContainer = payload;
  }
};

const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetMiniDrawer = commit(mutations.setMiniDrawer);
export const commitSetShowDrawer = commit(mutations.setShowDrawer);
export const commitSetShowAppBar = commit(mutations.setShowAppBar);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetShowNotFound = commit(mutations.setShowNotFound);
export const commitSetShowNotAuthorized = commit(mutations.setShowNotAuthorized);
export const commitSetSystemSettings = commit(mutations.setSystemSettings);
export const commitSetDisplayContainerFillHeight = commit(mutations.setDisplayContainerFillHeight);
export const commitSetShowNewContainer = commit(mutations.setShowNewContainer);