import { ISubject, ISubjectCreate } from "@/interfaces/aggregation/subject";
import { commitAddNotification, commitRemoveNotification } from "@/store/main/mutations";
import { commitRemoveSubject, commitSetSubjectContainers, commitSetSubjectContainersTotal, commitSetSubjects, commitSetSubjectsTotal } from "@/store/subject/mutations";

import { ActionContext } from "vuex";
import { State } from "@/store/state";
import { SubjectState } from "@/store/subject/state";
import api from "@/api";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type SubjectContext = ActionContext<SubjectState, State>;

export const actions = {
  async actionGetSubjects(context: SubjectContext, { offset = 0, limit = 100, clientSort = false }) {
    try {
      const response = await api.getSubjects(
        offset,
        limit
      );

      if (response.data) {
        if (clientSort) {
          commitSetSubjects(context, response.data.sort((a, b) => (a.subject > b.subject) ? 1 : -1));
        } else {
          commitSetSubjects(context, response.data);
        }

        commitSetSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetSubjects(context, []);
        commitSetSubjectsTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionSearchSubjects(context: SubjectContext, { searchQuery = "", approximate = false, offset = 0, limit = 100 }) {
    try {
      const response = await api.searchSubjects(
        { subject: searchQuery, approximate: approximate },
        offset,
        limit
      );

      if (response.data) {
        commitSetSubjects(context, response.data);
        commitSetSubjectsTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetSubjects(context, []);
        commitSetSubjectsTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionAddSubject(context: SubjectContext, subject: ISubjectCreate) {
    const loadingNotification = {
      content: "Adding subject...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addSubject( subject);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully added the ${subject.subject} subject.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null
  },

  async actionDeleteSubject(context: SubjectContext, subject: ISubject) {
    const loadingNotification = {
      content: "Deleting subject ...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      (
        await Promise.all([
          api.deleteSubject(subject.id),
          await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
        ])
      )[0];

      commitRemoveNotification(context, loadingNotification);
      await commitRemoveSubject(context, subject);

      commitAddNotification(context, {
        content: `Successfully deleted the ${subject.subject} subject.`,
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetSubjectsTotal(context: SubjectContext) {
    try {
      const response = await api.getSubjectStats(
      );

      if (response.status === 200) {
        commitSetSubjectsTotal(context, parseInt(response.headers["x-total"]));
      } else {
        commitSetSubjectsTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetSubjectContainers(context: SubjectContext, info: { subject: ISubject, offset: number, limit: number }) {
    try {
      const response = await api.getSubjectContainers(
        info.subject.id,
        info.offset,
        info.limit
      );

      if (response.data) {
        commitSetSubjectContainers(context, response.data);
        commitSetSubjectContainersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetSubjectContainers(context, []);
        commitSetSubjectContainersTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  }

}

const { dispatch } = getStoreAccessors<SubjectContext | any, State>("");

export const dispatchGetSubjects = dispatch(actions.actionGetSubjects);
export const dispatchSearchSubjects = dispatch(actions.actionSearchSubjects);
export const dispatchAddSubject = dispatch(actions.actionAddSubject);
export const dispatchDeleteSubject = dispatch(actions.actionDeleteSubject);
export const dispatchGetSubjectsTotal = dispatch(actions.actionGetSubjectsTotal);

export const dispatchGetSubjectContainers = dispatch(actions.actionGetSubjectContainers);