const newRoutes = {
  path: "/new",
  component: () =>
    import(/* webpackChunkName: "new" */ "../views/new/StartView.vue"),
  children: [
    {
      path: "",
      name: "new",
      component: () =>
        import(
          /* webpackChunkName: "new-home" */ "../views/new/HomeView.vue"
        ),
    },

  ]
}

export default newRoutes