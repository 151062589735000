const env = process.env.VUE_APP_ENV;

let envAPIUrl = "http://localhost:8000";
let envAssertUrl = "http://localhost:8000/assets";

if (env === "production") {
  envAPIUrl = "https://api.pyfunceble.com";
  envAssertUrl = "https://assets.pyfunceble.com";
} else if (env === "staging") {
  envAPIUrl = "https://api.stg.pyfunceble.com";
  envAssertUrl = "https://assets.stg.pyfunceble.com";
} else if (env === "development") {
  envAPIUrl = "https://api.dev.pyfunceble.com";
  envAssertUrl = "https://assets.dev.pyfunceble.com";
} else {
  envAPIUrl = "http://localhost:8000";
  envAssertUrl = "http://localhost:8000/assets";
}

if (process.env.VUE_APP_API_URL) {
  envAPIUrl = `${process.env.VUE_APP_API_URL}`;
}

if (process.env.VUE_APP_ASSETS_URL) {
  envAssertUrl = `${process.env.VUE_APP_ASSETS_URL}`;
}

export const apiUrl = envAPIUrl;
export const assetsUrl = envAssertUrl;
export const appName = process.env.VUE_APP_NAME || "Dead-Hosts";

export const defaultItemsOffset = 0;
export const defaultItemsPerPage = 50;
export const defaultTheme = "dark";