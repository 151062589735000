import { AdminState } from "@/store/admin/state";
import { actions } from "@/store/admin/actions";
import { getters } from "@/store/admin/getters";
import { mutations } from "@/store/admin/mutations";

const defaultState: AdminState = {
};

export const adminModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
