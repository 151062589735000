import { IUser, IUserScopes, IUserSettings } from "@/interfaces/user";

import { AuthState } from "@/store/auth/state";
import { IContainer } from "@/interfaces/container";
import { IMachine } from "@/interfaces/machine";
import { IUserTokenMinimal } from "@/interfaces/auth/token";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const mutations = {
  setLoggedIn(state: AuthState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLoginError(state: AuthState, payload: boolean) {
    state.loginError = payload;
  },
  setPasswordRecoveryError(state: AuthState, payload: boolean) {
    state.passwordRecoveryError = payload
  },
  setPasswordResetError(state: AuthState, payload: boolean) {
    state.passwordResetError = payload
  },
  setLoggedInUser(state: AuthState, payload: IUser | null) {
    state.loggedInUser = payload;
  },
  setLoggedInUserSettings(state: AuthState, payload: IUserSettings) {
    if (state.loggedInUser && payload) {
      state.loggedInUser.settings = payload;
    }
  },
  setLoggedInUserTokens(state: AuthState, payload: IUserTokenMinimal[]) {
    state.loggedInUserTokens = payload;
  },
  addLoggedInUserToken(state: AuthState, payload: IUserTokenMinimal) {
    const tokens = state.loggedInUserTokens.filter(
      (token: IUserTokenMinimal) => token.id !== payload.id
    );
    tokens.push(payload);
    state.loggedInUserTokens = tokens;
  },
  removeLoggedInUserToken(state: AuthState, payload: IUserTokenMinimal) {
    const tokens = state.loggedInUserTokens.filter(
      (token: IUserTokenMinimal) => token.id !== payload.id
    );
    state.loggedInUserTokens = tokens;
  },
  setLoggedInUserMachines(state: AuthState, payload: IMachine[]) {
    state.loggedInUserMachines = payload;
  },
  addLoggedInUserMachine(state: AuthState, payload: IMachine) {
    const machines = state.loggedInUserMachines.filter(
      (machine: IMachine) => machine.id !== payload.id
    );
    machines.push(payload);
    state.loggedInUserMachines = machines;
  },
  removeLoggedInUserMachine(state: AuthState, payload: IMachine) {
    const machines = state.loggedInUserMachines.filter(
      (machine: IMachine) => machine.id !== payload.id
    );
    state.loggedInUserMachines = machines;
  },
  setLoggedInUserMachinesTotal(state: AuthState, payload: number) {
    state.loggedInUserMachinesTotal = payload
  },
  setLoggedInUserContainers(state: AuthState, payload: IContainer[]) {
    state.loggedInUserContainers = payload;
  },
  addLoggedInUserContainer(state: AuthState, payload: IContainer) {
    const containers = state.loggedInUserContainers.filter(
      (container: IContainer) => container.id !== payload.id
    );
    containers.push(payload);
    state.loggedInUserContainers = containers;
  },
  removeLoggedInUserContainer(state: AuthState, payload: IContainer) {
    const containers = state.loggedInUserContainers.filter(
      (container: IContainer) => container.id !== payload.id
    );
    state.loggedInUserContainers = containers;
  },
  setLoggedInUserContainersTotal(state: AuthState, payload: number) {
    state.loggedInUserContainersTotal = payload;
  },
  setLoggedInUserScopes(state: AuthState, payload: IUserScopes | null) {
    state.loggedInUserScopes = payload;
  },
};

const { commit } = getStoreAccessors<AuthState | any, State>("");

export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLoginError = commit(mutations.setLoginError);
export const commitSetPasswordRecoveryError = commit(mutations.setPasswordRecoveryError);
export const commitSetPasswordResetError = commit(mutations.setPasswordResetError);

export const commitSetLoggedInUser = commit(mutations.setLoggedInUser);
export const commitSetLoggedInUserSettings = commit(mutations.setLoggedInUserSettings);
export const commitSetLoggedInUserTokens = commit(mutations.setLoggedInUserTokens);
export const commitAddLoggedInUserToken = commit(mutations.addLoggedInUserToken);
export const commitRemoveLoggedInUserToken = commit(mutations.removeLoggedInUserToken);

export const commitSetLoggedInUserMachines = commit(mutations.setLoggedInUserMachines);
export const commitAddLoggedInUserMachine = commit(mutations.addLoggedInUserMachine);
export const commitRemoveLoggedInUserMachine = commit(mutations.removeLoggedInUserMachine);
export const commitSetLoggedInUserMachinesTotal = commit(mutations.setLoggedInUserMachinesTotal);

export const commitSetLoggedInUserContainers = commit(mutations.setLoggedInUserContainers);
export const commitAddLoggedInUserContainer = commit(mutations.addLoggedInUserContainer);
export const commitRemoveLoggedInUserContainer = commit(mutations.removeLoggedInUserContainer);
export const commitSetLoggedInUserContainersTotal = commit(mutations.setLoggedInUserContainersTotal);

export const commitSetLoggedInUserScopes = commit(mutations.setLoggedInUserScopes);
