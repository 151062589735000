<template>
  <v-app>
    <AppBar v-model="showAppBar"></AppBar>

    <v-main v-if="showLoader">
      <v-container fill-height>
        <v-layout align-center justify-center>
          <v-flex>
            <div class="text-center">
              <div class="headline my-4">Loading...</div>
              <v-progress-circular
                :size="100"
                :width="8"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-main v-else>
      <v-container :fill-height="displayContainerFillHeight" fluid>
        <NotAuthorized v-if="showNotAuthorized"></NotAuthorized>
        <NotFoundView v-else-if="showNotFound"></NotFoundView>
        <router-view v-else></router-view>
        <NotificationsManager></NotificationsManager>
        <NewContainer v-model="showNewContainer"></NewContainer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import NotificationsManager from "@/components/NotificationsManager.vue";
import LoginManager from "@/components/LoginManager.vue";
import { Component, Mixins } from "vue-property-decorator";
import AppBar from "@/components/AppBar.vue";
import { appName } from "@/consts";
import AppBase from "@/AppBase";
import NotFoundView from "@/views/NotFound.vue";
import NotAuthorized from "@/views/NotAuthorized.vue";
import { dispatchReadSystemSettings } from "./store/main/actions";
import {
  commitSetDisplayContainerFillHeight,
} from "./store/main/mutations";
import NewContainer from "@/components/new/containers/NewContainer.vue";

@Component({
  components: {
    NotificationsManager,
    LoginManager,
    AppBar,
    NotFoundView,
    NotAuthorized,
    NewContainer,
  },
  metaInfo: {
    title: "Home",
    titleTemplate: "%s | " + appName,
  },
})
export default class App extends Mixins(AppBase) {
  public async created() {
    this.showAppBar;
    await dispatchReadSystemSettings(this.$store, false);
    await commitSetDisplayContainerFillHeight(this.$store, false);
  }
}
</script>
