import { MainState } from "@/store/main/state";
import { actions } from "@/store/main/actions";
import { getters } from "@/store/main/getters";
import { mutations } from "@/store/main/mutations";

const defaultState: MainState = {
  miniDrawer: false,
  showDrawer: true,
  showAppBar: true,
  notifications: [],
  showNotFound: false,
  showNotAuthorized: false,
  systemSettings: null,
  displayContainerFillHeight: false,
  showNewContainer: false,
};

export const mainModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
