import { IUserSettings, IUserUpdate, IUserUpdateBio, IUserUpdatePassword, IUserUpdateUsername, PreferredStyleEnum } from "@/interfaces/user";
import { commitAddNotification, commitRemoveNotification } from "@/store/main/mutations";
import { commitSetLoggedInUser, commitSetLoggedInUserSettings } from "@/store/auth/mutations";
import { commitSetPasswordUpdateError, commitSetUsernameUpdateError } from "@/store/dashboard/mutations";

import { ActionContext } from "vuex";
import { DashboardState } from "@/store/dashboard/state";
import { State } from "@/store/state";
import api from "@/api";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type DashboardContext = ActionContext<DashboardState, State>;

export const actions = {
  async actionUpdateMe(context: DashboardContext, payload: IUserUpdate) {
    const loadingNotification = {
      content: "Updating your information...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);
    try {
      const response = (
        await Promise.all([
          api.updateMe(payload),
          await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
        ])
      )[0];

      commitSetLoggedInUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Successfully updated your information",
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionUpdateMeUsername(
    context: DashboardContext,
    payload: IUserUpdateUsername
  ) {
    const loadingNotification = {
      content: "Updating your username...",
      color: "info",
      showProgress: true,
    };
    commitAddNotification(context, loadingNotification);

    try {
      await Promise.all([
        api.updateMeUsername(payload),
        await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
      ]);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Successfully updated your username",
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitSetUsernameUpdateError(context, true);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionUpdateMeBio(
    context: DashboardContext,
    payload: IUserUpdateBio
  ) {
    const loadingNotification = {
      content: "Updating your bio...",
      color: "info",
      showProgress: true,
    };
    commitAddNotification(context, loadingNotification);

    try {
      await Promise.all([
        api.updateMeBio(payload),
        await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
      ]);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Successfully updated your bio.",
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionUpdateMePassword(
    context: DashboardContext,
    payload: IUserUpdatePassword
  ) {
    const loadingNotification = {
      content: "Updating your password...",
      color: "info",
      showProgress: true,
    };
    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateMePassword(payload);

      await new Promise((resolve) => setTimeout(() => resolve(true), 500));

      commitSetPasswordUpdateError(context, false);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Successfully updated your password",
        color: "success",
      });
      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitSetPasswordUpdateError(context, true);
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionUpdateMeSettings(context: DashboardContext, payload: IUserSettings) {
    const loadingNotification = {
      content: "Updating your settings...",
      color: "info",
      showProgress: true,
    };
    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateMeSettings(
        payload
      )

      await new Promise((resolve) => setTimeout(() => resolve(true), 500));
      commitRemoveNotification(context, loadingNotification);

      commitAddNotification(context, {
        content: "Successfully updated your settings.",
        color: "success",
      });

      if (response.data) {
        commitSetLoggedInUserSettings(context, response.data);
      }

      return response.data

    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error)
    }
    return null
  },

  async actionUpdateMePreferredStyle(context: DashboardContext, payload: PreferredStyleEnum) {
    try {
      const response = await api.updateMeSettings(
        {
          preferred_style: payload
        }
      )

      if (response.data) {
        commitSetLoggedInUserSettings(context, response.data);
      }

      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }

    return null
  },
};

const { dispatch } = getStoreAccessors<DashboardState, State>("");

export const dispatchUpdateMe = dispatch(
  actions.actionUpdateMe
);
export const dispatchUpdateMePassword = dispatch(
  actions.actionUpdateMePassword
);
export const dispatchUpdateMeUsername = dispatch(
  actions.actionUpdateMeUsername
)
export const dispatchUpdateMeBio = dispatch(actions.actionUpdateMeBio)
export const dispatchUpdateMeSettings = dispatch(
  actions.actionUpdateMeSettings
)
export const dispatchUpdateMePreferredStyle = dispatch(actions.actionUpdateMePreferredStyle)