import { StatsState } from "@/store/stats/state";
import { actions } from "@/store/stats/actions";
import { getters } from "@/store/stats/getters";
import { mutations } from "@/store/stats/mutations";

const defaultState: StatsState = {
}

export const statsModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters
}