const adminRoutes = {
  path: "/admin",
  component: () =>
    import(
            /* webpackChunkName: "admin-start" */ "../views/admin/StartView.vue"
    ),
  children: [
    {
      path: "",
      name: "admin",
      component: () =>
        import(
                /* webpackChunkName: "admin-home" */ "../views/admin/HomeView.vue"
        ),
    },
    {
      path: "users",
      name: "admin-users",
      component: () =>
        import(
                /* webpackChunkName: "admin-users" */ "../views/admin/UsersView.vue"
        ),
    },
    {
      path: "machines",
      name: "admin-machines",
      component: () =>
        import(
                /* webpackChunkName: "admin-machines" */ "../views/admin/MachinesView.vue"
        ),
    },
    {
      path: "subjects",
      name: "admin-subjects",
      component: () =>
        import(
                /* webpackChunkName: "admin-subjects" */ "../views/admin/SubjectsView.vue"
        ),
    },
    {
      path: "roles",
      name: "admin-roles",
      component: () =>
        import(
                /* webpackChunkName: "admin-roles" */ "../views/admin/RolesView.vue"
        ),
    },
    {
      path: "containers",
      name: "admin-containers",
      component: () =>
        import(
                /* webpackChunkName: "admin-containers" */ "../views/admin/ContainersView.vue"
        ),
    },
    {
      path: "reserved-users",
      name: "admin-reserved-users",
      component: () =>
        import(
                /* webpackChunkName: "admin-reserved-users" */ "../views/admin/ReservedUsersView.vue"
        ),
    },
    {
      path: "system-settings",
      name: "admin-system-settings",
      component: () =>
        import(
                /* webpackChunkName: "admin-system-settings" */ "../views/admin/SystemSettingsView.vue"
        )
    }
  ],
}

export default adminRoutes;