import { IMachine } from "@/interfaces/machine";
import { MachineState } from "@/store/machine/state"
import { State } from "@/store/state"
import { getStoreAccessors } from "typesafe-vuex"

export const mutations = {
  setMachines(state: MachineState, payload: IMachine[]) {
    state.machines = payload;
  },
  addMachine(state: MachineState, payload: IMachine) {
    const machines = state.machines.filter(
      (machine: IMachine) => machine.id !== payload.id
    );
    machines.push(payload);
    state.machines = machines;
  },
  removeMachine(state: MachineState, payload: IMachine) {
    const machines = state.machines.filter(
      (machine: IMachine) => machine.id !== payload.id
    );
    state.machines = machines;
  },
  setMachineTotal(state: MachineState, payload: number) {
    state.machinesTotal = payload;
  },

}

const { commit } = getStoreAccessors<MachineState | any, State>("");

export const commitSetMachines = commit(mutations.setMachines);
export const commitAddMachine = commit(mutations.addMachine);
export const commitRemoveMachine = commit(mutations.removeMachine);
export const commitSetMachineTotal = commit(mutations.setMachineTotal);
