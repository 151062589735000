<template>
  <v-snackbar
    v-model="show"
    :color="accentColor"
    elevation="24"
    app
    dark
    tile
    :multi-line="content.includes('\n')"
    class="text-pre-wrap"
  >
    <v-layout align-center pr-4>
      <v-icon v-if="icon" class="pr-3" dark large>
        {{ icon }}
      </v-icon>
      <v-layout column>
        <div v-if="title">
          <strong>{{ title }}</strong>
        </div>
        <div>{{ content }}</div>
      </v-layout>
      <v-layout v-if="showProgress" column>
        <v-spacer />
        <v-progress-circular class="ma-3" indeterminate></v-progress-circular>
      </v-layout>
      <v-layout v-if="timeout == null" column>
        <v-btn icon v-bind="attrs" @click.native="close"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-layout>
    </v-layout>
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Watch, Mixins, Emit } from "vue-property-decorator";
import { SystemNotification } from "@/interfaces/system";
import { commitRemoveNotification } from "@/store/main/mutations";
import { readFirstNotification } from "@/store/main/getters";
import { dispatchRemoveNotification } from "@/store/main/actions";
import AppBase from "@/AppBase";

@Component
export default class NotificationsManager extends Mixins(AppBase) {
  public show = false;
  public currentNotification: SystemNotification | null = null;
  public showProgress = false;
  public title = "";
  public content = "";
  public accentColor = "";
  public icon = "";
  public timeout = 3000;

  public get firstNotification() {
    return readFirstNotification(this.$store);
  }

  @Watch("firstNotification", { immediate: true })
  public async onNotificationChange(
    newNotification: SystemNotification | null
  ) {
    if (newNotification !== this.currentNotification && newNotification) {
      await this.setNotification(newNotification);

      if (newNotification) {
        dispatchRemoveNotification(this.$store, {
          notification: newNotification,
          timeout: this.timeout,
        });
      }
    }
  }

  public iconFromColor(color: string) {
    if (color === "error") {
      return "mdi-alert-circle";
    }
    if (color === "warning") {
      return "mdi-alert";
    }
    if (color === "info") {
      return "mdi-information";
    }
    if (color === "success") {
      return "mdi-check-circle";
    }

    return "";
  }

  public async removeCurrentNotification() {
    if (this.currentNotification) {
      commitRemoveNotification(this.$store, this.currentNotification);
    }
  }

  public async setNotification(notification: SystemNotification | null) {
    if (this.show) {
      await this.hide();
    }

    if (notification) {
      this.currentNotification = notification;
      this.showProgress = notification.showProgress || false;
      this.title = notification.title || "";
      this.content = notification.content || "";
      this.accentColor = notification.color || "" + " accent-6";
      this.timeout = notification.timeout || 3000;
      this.icon = notification.icon || this.iconFromColor(this.accentColor);
      this.show = true;
    } else {
      this.currentNotification = null;
    }
  }

  public async close() {
    await this.hide();
    await this.removeCurrentNotification();
  }

  @Emit("hidden")
  public async hide() {
    this.show = false;
    await new Promise((resolve) => setTimeout(() => resolve(true), 500));
  }
}
</script>