import { UserState } from "@/store/user/state";
import { actions } from "@/store/user/actions";
import { getters } from "@/store/user/getters";
import { mutations } from "@/store/user/mutations";

const defaultState: UserState = {
  users: [],
  usersTotal: 0,
  uData: null,
  uContainers: [],
  uContainersTotal: 0
}

export const userModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters
}