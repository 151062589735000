import { State } from "@/store/state"
import { SubjectState } from "@/store/subject/state"
import { getStoreAccessors } from "typesafe-vuex"

export const getters = {
  subjects: (state: SubjectState) => state.subjects,
  subjectsTotal: (state: SubjectState) => state.subjectsTotal,
  subjectContainers: (state: SubjectState) => state.subjectContainers,
  subjectContainersTotal: (state: SubjectState) => state.subjectContainersTotal
}

const { read } = getStoreAccessors<SubjectState, State>("");

export const readSubjects = read(getters.subjects);
export const readSubjectsTotal = read(getters.subjectsTotal);
export const readSubjectContainers = read(getters.subjectContainers);
export const readSubjectContainersTotal = read(getters.subjectContainersTotal);

