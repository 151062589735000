import Vuex, { StoreOptions } from "vuex";

import { State } from "@/store/state";
import Vue from "vue";
import { adminModule } from "@/store/admin";
import { authModule } from "@/store/auth";
import { containerModule } from "@/store/container"
import { dashboardModule } from "@/store/dashboard";
import { exploreModule } from "@/store/explore";
import { machineModule } from "@/store/machine";
import { mainModule } from "@/store/main";
import { reservedUserModule } from "@/store/reservedUser";
import { roleModule } from "@/store/role";
import { statsModule } from "@/store/stats";
import { subjectModule } from "@/store/subject";
import { userModule } from "@/store/user";

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    admin: adminModule,
    auth: authModule,
    container: containerModule,
    dashboard: dashboardModule,
    explore: exploreModule,
    machine: machineModule,
    main: mainModule,
    reservedUser: reservedUserModule,
    role: roleModule,
    stats: statsModule,
    subject: subjectModule,
    user: userModule,
  },
  strict: process.env.NODE_ENV !== "production",
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
