import { extend } from "vee-validate";

import api from "@/api";

extend("uniq_email", {
    params: ["actual_email"],
    validate: async (value, { actual_email }: IVeeValidateParameter) => {
      if (value === actual_email) {
        return true;
      }

      if (!value) {
        return false;
      }

      try {
        await api.checkUserEmail(value);

        return false;
      } catch (error) {
        return true;
      }
    },
    message: "{_field_} is already taken",
  });
