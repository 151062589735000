import axios, { AxiosResponse } from "axios";

import { apiUrl } from "@/consts";

export const utilsAPI = {
  async generatePassword(): Promise<
    AxiosResponse<IPasswordGenerationResponse, any>
  > {
    return axios.get<IPasswordGenerationResponse>(
      `${apiUrl}/v1/utils/password/generate`
    );
  },
};

export default utilsAPI;