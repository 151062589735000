import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

import { ActionContext } from "vuex";
import { AdminState } from "@/store/admin/state";
import { ISystemSettings } from "@/interfaces/system";
import { State } from "@/store/state";
import api from "@/api";
import { getStoreAccessors } from "typesafe-vuex";

type AdminContext = ActionContext<AdminState, State>;

export const actions = {
  async actionUpdateSystemSettings(context: AdminContext, payload: ISystemSettings) {
    const loadingNotification = { content: "Updating system settings...", color: "info", showProgress: true };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateSystemSettings(payload);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { content: "System settings updated", color: "success" });

      return response.data;
    } catch (error) {
      commitAddNotification(context, { content: `Couldn't update system settings. ${error}`, color: "error" });
    }
    return null;
  }
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchUpdateSystemSettings = dispatch(actions.actionUpdateSystemSettings);