import { apiUrl } from "@/consts";
import axios from "axios";

export const hubProjectCheckAPI = {

};

export const hubSubjectCheckAPI = {

  // TODO: Checks this ASAP.
  async checkHubSubjectID(id: string) {

    return axios.head<null>(
      `${apiUrl}/v1/hub/subject/${id}`,
    );
  }
}

export const hubProjectAPI = {
  ...hubProjectCheckAPI,

};

export const hubSubjectAPI = {
  ...hubSubjectCheckAPI,
}

export const hubAPI = {
  ...hubProjectAPI,
  ...hubSubjectAPI
};

export default hubAPI;