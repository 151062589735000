import { IReservedUser } from "@/interfaces/reservedUsers";
import { ReservedUserState } from "@/store/reservedUser/state"
import { getStoreAccessors } from "typesafe-vuex"

export const mutations = {
    setReservedUsers(state: ReservedUserState, payload: IReservedUser[]) {
        state.reservedUsers = payload;
    },
    addReservedUser(state: ReservedUserState, payload: IReservedUser) {
        const reservedUsers = state.reservedUsers.filter(
            (reservedUser: IReservedUser) => reservedUser.id !== payload.id
        );
        reservedUsers.push(payload);

        state.reservedUsers = reservedUsers;
    },
    removeReservedUser(state: ReservedUserState, payload: IReservedUser) {
        const reservedUsers = state.reservedUsers.filter(
            (reservedUser: IReservedUser) => reservedUser.id !== payload.id
        );

        state.reservedUsers = reservedUsers;
    },
    setReservedUsersTotal(state: ReservedUserState, payload: number) {
        state.reservedUsersTotal = payload;
    }
}

const { commit } = getStoreAccessors<ReservedUserState | any, any>("");

export const commitSetReservedUsers = commit(mutations.setReservedUsers);
export const commitAddReservedUser = commit(mutations.addReservedUser);
export const commitRemoveReservedUser = commit(mutations.removeReservedUser);
export const commitSetReservedUsersTotal = commit(mutations.setReservedUsersTotal);
