// TODO: Store this in a safe maner.
export const getToken = () => localStorage.getItem("token") || "";
export const saveToken = (token: string) =>
  localStorage.setItem("token", token);
export const removeToken = () => localStorage.removeItem("token");

export const saveTokenID = (tokenID: string) =>
  localStorage.setItem("tokenID", tokenID);
export const getTokenID = () => localStorage.getItem("tokenID") || "";
export const removeTokenID = () => localStorage.removeItem("tokenID");

export const saveIsLoggedIn = (isLoggedIn: boolean) =>
  localStorage.setItem("isLoggedIn", isLoggedIn.toString());
export const getIsLoggedIn = () => localStorage.getItem("isLoggedIn") === "true";
export const removeIsLoggedIn = () => localStorage.removeItem("isLoggedIn");

// TODO: Implement this somedays. (dark mode from browser settings.)
// window.matchMedia('(prefers-color-scheme: dark)')
//       .addEventListener('change', event => {
//   if (event.matches) {
//     //dark mode
//   } else {
//     //light mode
//   }
// })