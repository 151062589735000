import { extend } from "vee-validate";

extend("valid_domain", {
  validate: (value) => {
    const regex = new RegExp(
      /\b((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}\b/gm
    );

    return regex.test(value);
  },
  message: "{_value_} is not a valid domain.",

});
