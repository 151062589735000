import aggregationAPI from "./aggregation";
import authAPI from "./auth";
import containersAPI from "./container";
import hubAPI from "./hub";
import machineAPI from "./machine";
import meAPI from "./me";
import reservedUsersAPI from "./reservedUser";
import roles from "./roles"
import systemAPI from "./system";
import userAPI from "./user";
import utilsAPI from "./utils";
import axios from "axios";

export const api = {
    ...aggregationAPI,
    ...authAPI,
    ...containersAPI,
    ...hubAPI,
    ...machineAPI,
    ...meAPI,
    ...reservedUsersAPI,
    ...roles,
    ...userAPI,
    ...utilsAPI,
    ...systemAPI,
    isAxiosError(x: any): boolean { return axios.isAxiosError(x) },
};

export default api;
export const isAxiosError = axios.isAxiosError;