<template>
  <v-dialog
    v-model="show"
    v-show="show"
    persistent
    max-width="80%"
    overlay-color="rgba(24, 40, 14, 0.5)"
    transition="dialog-bottom-transition"
  >
    <v-stepper v-model="stepperProgress">
      <v-stepper-items>
        <v-stepper-content class="pa-0" step="1">
          <ContainerCreatorForm
            v-model="showContainerCreator"
            :vtoolbar="true"
            :hideOnSuccess="false"
            @hidden="hide"
            @success="onContainerCreatorSuccess"
            @input.native.stop
            @click.native.stop
          ></ContainerCreatorForm>
        </v-stepper-content>
        <v-stepper-content class="pa-0" step="2">
          <v-card>
            <v-card-title class="justify-center"
              >What do you want to do next?</v-card-title
            >
            <v-row dense no-gutters>
              <v-col cols="6">
                <v-btn
                  color="green darken-1"
                  x-large
                  depressed
                  tile
                  block
                  @click="stepperProgress += 1"
                  >Add Subject</v-btn
                >
              </v-col>
              <v-col cols="6">
                <v-btn
                  color="primary darken-1"
                  x-large
                  tile
                  block
                  @click="moveToContainer"
                  >Move to Container</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-stepper-content>
        <v-stepper-content class="pa-0" step="3">
          <ContainerSubjectAdditionForm
            v-model="showContainerCreator"
            :vtoolbar="true"
            :hideOnSuccess="false"
            :containerData="newContainerData"
            @hidden="hide"
            @success="moveToContainer"
            @input.native.stop
            @click.native.stop
          >
            <template v-slot:additionalButton>
              <v-btn
                color="purple darken-1"
                depressed
                rounded
                @click="moveToContainer"
              >
                To Container
              </v-btn>
            </template>
          </ContainerSubjectAdditionForm>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-dialog>
</template>
<script lang="ts">
import ComponentBase from "@/ComponentBase";
import { Component, Mixins, Watch, Emit } from "vue-property-decorator";

import ContainerCreatorForm from "@/components/forms/containers/ContainerCreatorForm.vue";
import ContainerSubjectAdditionForm from "@/components/forms/containers/ContainerSubjectAdditionForm.vue";
import { IContainer } from "@/interfaces/container";

@Component({
  components: { ContainerCreatorForm, ContainerSubjectAdditionForm },
})
export default class NewContainer extends Mixins(ComponentBase) {
  public showContainerCreator = true;
  public stepperProgress = 1;
  public newContainerData: IContainer = {} as IContainer;

  public containerCreatorTitle = "Create Container";
  public containerSubjectAdditionTitle = "Add subject into container";

  public showDialog() {
    return this.show;
  }

  @Watch("show", { immediate: true })
  public onShowChanged(newValue: boolean) {
    this.showContainerCreator = newValue;
    if (!newValue) {
      this.stepperProgress = 1;
    }
  }

  public onContainerCreatorSuccess(container: IContainer) {
    this.newContainerData = container;
    this.stepperProgress += 1;
  }

  @Emit("success")
  public moveToContainer() {
    if (this.hideOnSuccess) {
      this.hide();
    }

    this.exploreContainer(this.newContainerData);

    return this.newContainerData;
  }
}
</script>