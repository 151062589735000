import { MachineState } from "@/store/machine/state"
import { State } from "@/store/state"
import { getStoreAccessors } from "typesafe-vuex"

export const getters = {
    machines: (state: MachineState) => state.machines,
    machinesTotal: (state: MachineState) => state.machinesTotal,
}

const { read } = getStoreAccessors<MachineState, State>("");

export const readMachines = read(getters.machines);
export const readMachinesTotal = read(getters.machinesTotal);