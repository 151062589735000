export default function downloadBlob(blob: Blob, filename: string) {
    const blobLink = URL.createObjectURL(blob)
    const HTMLink = document.createElement("a")

    HTMLink.href = blobLink;
    HTMLink.setAttribute("download", filename);
    document.body.appendChild(HTMLink);
    HTMLink.click();

    document.body.removeChild(HTMLink);
    URL.revokeObjectURL(blobLink);
}