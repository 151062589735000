<template>
  <v-app-bar v-model="show" app dark>
    <router-link :to="{ name: 'home' }">
      <v-img
        alt="Dead-Hosts Logo"
        class="shrink mr-2"
        contain
        :src="defaultAvatar"
        transition="scale-transition"
        width="40"
      />
    </router-link>
    <v-spacer></v-spacer>

    <div>
      <v-btn
        v-for="item in tabsItems"
        :key="item.title"
        :to="item.route"
        v-show="item.authorization"
        text
        class="mr-1"
      >
        <v-icon v-if="item.icon" left :color="item.iconColor">
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </v-btn>
    </div>
    <v-spacer></v-spacer>

    <v-btn
      v-if="isLoggedIn"
      v-show="isLoggedIn"
      @click="showNewContainer = true"
      class="mr-2"
      dark
      x-small
      icon
      color="blue"
    >
      <v-icon dark>mdi-plus-thick</v-icon>
    </v-btn>

    <v-btn
      v-if="!isLoggedIn"
      v-show="!isLoggedIn"
      :to="{ name: 'login-auth' }"
      color="magenta"
      class="ma-2 white--text"
    >
      Login
      <v-icon
        right
        dark
      >
        mdi-login
      </v-icon>
    </v-btn>

    <v-menu
      v-if="isLoggedIn"
      :nudge-width="200"
      :close-on-click="true"
      :close-on-content-click="false"
      tile
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          content=""
          value=""
          bordered
          bottom
          color="deep-purple accent-4"
          dot
          offset-x="10"
          offset-y="10"
        >
          <v-avatar v-bind="attrs" v-on="on" size="40">
            <v-img :alt="username + '\'s Avatar'" :src="avatar_url" @error="avatarFailed = true" />
          </v-avatar>
        </v-badge>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar left size="50">
              <v-img :alt="username + '\'s Avatar'" :src="avatar_url" @error="avatarFailed = true" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ displayName }}</v-list-item-title>
              <v-list-item-subtitle>@{{ username }}</v-list-item-subtitle>

              <v-container v-if="isSiteAdmin || isTrustedUser">
                <v-row>
                  <v-icon v-if="isSiteAdmin" dense color="yellow darken-2">
                    mdi-shield-crown
                  </v-icon>
                  <v-icon v-if="isTrustedUser" dense color="blue darken-2">
                    mdi-shield-check
                  </v-icon>
                </v-row>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item
            :to="{ name: 'account', params: { username: username } }"
          >
            <v-list-item-icon>
              <v-icon color="green">mdi-earth</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>
        <v-list>
          <v-list-item :to="{ name: 'me-settings' }">
            <v-list-item-icon>
              <v-icon color="blue darken-2">mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isSiteAdmin" :to="{ name: 'admin' }">
            <v-list-item-icon>
              <v-icon color="yellow darken-2">mdi-shield-crown-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Admin Dashboard</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Theme</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="darkThemeActive"
                color="indigo"
                @change="switchTheme"
                hide-details
              >
                <template v-slot:prepend>
                  <v-icon light color="yellow"> mdi-weather-sunny </v-icon>
                </template>

                <template v-slot:append>
                  <v-icon dark color="blue"> mdi-weather-night </v-icon>
                </template>
              </v-switch>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item @click.stop="logoutUser">
            <v-list-item-icon>
              <v-icon color="orange">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script lang="ts">
import ComponentBase from "@/ComponentBase";
import { Component, Mixins } from "vue-property-decorator";

@Component
export default class AppBar extends Mixins(ComponentBase) {
  public get tabsItems() {
    return [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        iconColor: "primary",
        route: {
          name: "dashboard",
        },
        authorization: this.isLoggedIn,
      },
      {
        title: "Explore",
        icon: "mdi-compass",
        iconColor: "orange darken-2",
        route: {
          name: "explore",
        },
        authorization: true,
      },
    ];
  }
}
</script>