<template>
  <div>
    <v-container fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm6 md4>
          <v-layout align-center justify-center>
            <v-avatar @click="exploreHome" size="5em">
              <v-img
                alt="Dead-Hosts Logo"
                class="shrink mr-2"
                contain
                src="https://avatars.githubusercontent.com/u/35098159?s=600&v=4"
                transition="scale-transition"
              />
            </v-avatar>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-card v-model="show" elevation="2" outlined>
      <v-tabs background-color="deep-purple" grow dark icons-and-text>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab>
          Sign In
          <v-icon>mdi-account</v-icon>
        </v-tab>

        <!-- <v-tab>
        Sign Up
        <v-icon>mdi-account-plus</v-icon>
      </v-tab> -->

        <v-tab-item>
          <v-card tile>
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <v-card-text>
                <v-form @keyup.enter="submitLogin">
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Username or Email"
                        vid="username"
                      >
                        <v-text-field
                          @keyup.enter="submitLogin"
                          autocomplete="username email"
                          v-model="login.username"
                          label="Username or Email"
                          type="text"
                          required
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                        name="Password"
                        vid="password"
                      >
                        <v-text-field
                          @keyup.enter="submitLogin"
                          autocomplete="current-password"
                          v-model="login.password"
                          label="Password"
                          type="password"
                          required
                          :error-messages="errors"
                        ></v-text-field>
                      </validation-provider>
                      <div v-if="false" v-show="false" class="text-right">
                        <i>
                          <router-link :to="{ name: 'password-auth-recovery' }">
                            Forgot Password?
                          </router-link>
                        </i>
                      </div>
                    </v-col>

                    <v-spacer></v-spacer>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-flex class="text-center">
                  <v-btn
                    @click.prevent="submitLogin"
                    x-large
                    tile
                    block
                    color="primary"
                    :disabled="invalid"
                  >
                    Login
                  </v-btn>
                </v-flex>
              </v-card-actions>
            </ValidationObserver>
          </v-card>

          <v-divider></v-divider>

          <v-card tile v-if="showSSOLogin" v-show="showSSOLogin">
            <v-card-title class="justify-center">Sign In with</v-card-title>
            <v-card-actions class="justify-center">
              <v-layout align-center justify-center>
                <v-flex>
                  <v-btn
                    @click="GitHubLogin"
                    v-if="showGitHubLogin"
                    v-show="showGitHubLogin"
                    block
                    tile
                    x-large
                    class="mt-2 mb-2"
                    color="#4078c0"
                  >
                    <v-icon class="mr-2">mdi-github</v-icon>
                    GitHub
                  </v-btn>
                  <v-btn
                    @click="GitLabLogin"
                    v-if="showGitLabLogin"
                    v-show="showGitLabLogin"
                    block
                    tile
                    x-large
                    class="mt-2 mb-2"
                    color="#fca326"
                  >
                    <v-icon class="mr-2">mdi-gitlab</v-icon>
                    GitLab
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card>
            <v-card-text>Coming Soon!</v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script lang="ts">
import ComponentBase from "@/ComponentBase";
import { Component, Mixins } from "vue-property-decorator";
import { readLoginError } from "@/store/auth/getters";
import { dispatchLogin } from "@/store/auth/actions";
import { ILoginCredentials } from "@/interfaces/login";
import { ValidationObserver } from "vee-validate";

@Component
export default class LoginManager extends Mixins(ComponentBase) {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  public login: ILoginCredentials = {
    username: "",
    password: "",
  };

  public get showGitHubLogin() {
    if (this.systemSettings === null) {
      return false;
    }

    return this.systemSettings.lock_github_login === false;
  }

  public get showGitLabLogin() {
    if (this.systemSettings === null) {
      return false;
    }

    return this.systemSettings.lock_gitlab_login === false;
  }

  public get showSSOLogin() {
    return this.showGitHubLogin || this.showGitLabLogin;
  }

  private async submitLogin() {
    if (await this.$refs.observer.validate()) {
      await dispatchLogin(this.$store, this.login);

      if (readLoginError(this.$store)) {
        this.$refs.observer.setErrors({
          username: "Incorrect Username or Email.",
          password: "Incorrect Password.",
        });
      }
    }
  }

  private GitHubLogin() {
    this.$router.push({ name: "github-auth" });
  }

  private GitLabLogin() {
    this.$router.push({ name: "gitlab-auth" });
  }
}
</script>