import { ISubjectStatus, SubjectSubmitFormatEnum, SubjectTypeEnum } from "./aggregation/subject";

import { IUser } from "./user";

export enum RemoteSubjectLinkTypeEnum {
  associated = "associated",
  ignored = "ignored",
  stashed = "stashed"
}

export interface IContainer {
  availability_test: boolean;
  description: string | null;
  id: string;
  display_name: string | null;
  public: boolean;
  reputation_test: boolean;
  name: string;
  syntax_test: boolean;
  subject_type: SubjectTypeEnum;
  owner: IUser;
  contributors?: IUser[];
  viewers?: IUser[];
  head?: string | null;
  subjects_count: number;
  availability_tested_count: number;
  availability_tested_percentage: number;
  syntax_tested_count: number;
  syntax_tested_percentage: number;
  reputation_tested_count: number;
  reputation_tested_percentage: number;
}

export interface IContainerCreate {
  availability_test?: boolean;
  description?: string | null;
  display_name?: string | null;
  public?: boolean;
  reputation_test?: boolean;
  name: string;
  syntax_test?: boolean;
  subject_type: SubjectTypeEnum;
  head?: string | null;
}

export interface IContainerUpdate {
  availability_test?: boolean;
  description?: string | null;
  display_name?: string | null;
  public?: boolean;
  reputation_test?: boolean;
  name: string;
  syntax_test?: boolean;
  subject_type: SubjectTypeEnum;
  head?: string | null;
}

export interface IContainerSearch {
  display_name?: string;
  name?: string;
  approximate?: boolean;
}

export interface IContainerSubject {
  id: string;
  subject: string;
  status: ISubjectStatus;
  link_type: RemoteSubjectLinkTypeEnum;
  link_locked: boolean;
}

export interface IContainerExportRuleRefsDownload {
  plain: string;
  hosts: string;
  compressed_hosts: string;
}

export interface IContainerExportRuleRefs {
  download?: IContainerExportRuleRefsDownload;
}

export interface IContainerExportRuleHostFormat {
  ipv4?: boolean;
  ipv6?: boolean;
  ipv4_address?: string;
  ipv6_address?: string;
}

export interface IContainerExportRuleAvailbility {
  active?: boolean;
  inactive?: boolean;
  invalid?: boolean;
}

export interface IContainerExportRuleSyntax {
  valid?: boolean;
  invalid?: boolean;
}

export interface IContainerExportRuleReputation {
  sane?: boolean;
  malicious?: boolean;
  invalid?: boolean;
}

export interface IContainerExportRule {
  id: string;
  name: string | null;
  permaname: string;
  description: string | null;
  generate_hosts_file: boolean;
  generate_plain_file: boolean;
  host_file: IContainerExportRuleHostFormat;
  availability: IContainerExportRuleAvailbility;
  syntax: IContainerExportRuleSyntax;
  reputation: IContainerExportRuleReputation;
  last_generation: Date;
  refs?: IContainerExportRuleRefs;
}

export interface IContainerExportRuleCreate {
  name?: string | null;
  description?: string | null;
  permaname: string;
  generate_hosts_file?: boolean;
  generate_plain_file?: boolean;
  subject_type?: SubjectTypeEnum;
  host_file?: IContainerExportRuleHostFormat;
  availability?: IContainerExportRuleAvailbility;
  syntax?: IContainerExportRuleSyntax;
  reputation?: IContainerExportRuleReputation;
}

export interface IContainerExportRuleUpdate {
  name?: string | null;
  description?: string | null;
  generate_hosts_file?: boolean;
  generate_plain_file?: boolean;
  subject_type?: SubjectTypeEnum;
  host_file?: IContainerExportRuleHostFormat;
  availability?: IContainerExportRuleAvailbility;
  syntax?: IContainerExportRuleSyntax;
  reputation?: IContainerExportRuleReputation;
}

export interface IContainerRemoteSubject {
  id: string,
  description: string,
  url: string,
  subject_type: SubjectTypeEnum,
  input_format: SubjectSubmitFormatEnum,
  link_type: RemoteSubjectLinkTypeEnum,
  container_id: string,
  last_download: Date,
  last_sha256: string | null,
  primary_nameserver: string,
  secondary_nameserver: string,
  zone_names: string[],
}

export interface IContainerRemoteSubjectCreate {
  description: string,
  url?: string,
  subject_type: SubjectTypeEnum,
  input_format: SubjectSubmitFormatEnum,
  link_type: RemoteSubjectLinkTypeEnum,
  primary_nameserver: string,
  secondary_nameserver: string,
  zone_names: string[],
}

export interface IContainerRemoteSubjectUpdate {
  description: string,
  url?: string,
  subject_type: SubjectTypeEnum,
  input_format: SubjectSubmitFormatEnum,
  link_type: RemoteSubjectLinkTypeEnum,
  primary_nameserver: string | null,
  secondary_nameserver: string | null,
  zone_names: string[] | null,
}