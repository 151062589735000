import { IAvailabilityStatus, IReputationStatus, ISyntaxStatus } from "@/interfaces/aggregation/status";
import {
  ISubject,
  ISubjectCreate,
  ISubjectInfo,
  ISubjectSearch,
} from "@/interfaces/aggregation/subject";

import { IContainer } from "@/interfaces/container";
import { apiUrl } from "@/consts";
import axios from "axios";
import { getIsLoggedIn } from "@/utils";

export const subjectAggregationMetadataAPI = {
  async getSubjectStats() {
    if (getIsLoggedIn()) {
      return axios.head<null>(
        `${apiUrl}/v1/aggregation/subject`,
      );
    }
    return axios.head<null>(
      `${apiUrl}/v1/hub/aggregation/subject`,
    );
  },
};

export const subjectAggregationCheckCheckAPI = {
  async checkSubject(subject: string) {
    if (getIsLoggedIn()) {
      return axios.post<null>(
        `${apiUrl}/v1/aggregation/subject/search`,
        {
          subject: subject,
        },
      );
    }
    return axios.post<null>(
      `${apiUrl}/v1/hub/aggregation/subject/search`,
      {
        subject: subject,
      },
    );
  },

  async checkSubjectID(id: string) {
    if (getIsLoggedIn()) {
      return axios.head<null>(
        `${apiUrl}/v1/aggregation/subject/${id}`,
      );
    }
    return axios.head<null>(
      `${apiUrl}/v1/hub/aggregation/subject/${id}`,
    );
  }

};

export const subjectAggregationAPI = {
  ...subjectAggregationMetadataAPI,
  ...subjectAggregationCheckCheckAPI,
  async getSubjects(offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<ISubjectInfo[]>(
        `${apiUrl}/v1/aggregation/subject?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<ISubjectInfo[]>(
      `${apiUrl}/v1/hub/aggregation/subject?offset=${offset}&limit=${limit}`,
    );
  },


  async getSubject(id: string) {
    if (getIsLoggedIn()) {
      return axios.get<ISubjectInfo>(
        `${apiUrl}/v1/aggregation/subject/${id}`,
      );
    }
    return axios.get<ISubjectInfo>(
      `${apiUrl}/v1/hub/aggregation/subject/${id}`,
    );
  },

  async getSubjectSyntaxStatus(id: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<ISyntaxStatus[]>(
        `${apiUrl}/v1/aggregation/subject/${id}/syntax?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<ISyntaxStatus[]>(
      `${apiUrl}/v1/hub/aggregation/subject/${id}/syntax?offset=${offset}&limit=${limit}`,
    );
  },

  async getSubjectAvailabilityStatus(id: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IAvailabilityStatus[]>(
        `${apiUrl}/v1/aggregation/subject/${id}/availability?offset=${offset}&limit=${limit}`,
      );
    }
    return axios.get<IAvailabilityStatus[]>(
      `${apiUrl}/v1/hub/aggregation/subject/${id}/availability?offset=${offset}&limit=${limit}`,
    );
  },

  async getSubjectReputationStatus(id: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IReputationStatus[]>(
        `${apiUrl}/v1/aggregation/subject/${id}/reputation?offset=${offset}&limit=${limit}`,
      );
    }

    return axios.get<IReputationStatus[]>(
      `${apiUrl}/v1/hub/aggregation/subject/${id}/reputation?offset=${offset}&limit=${limit}`,
    );
  },


  async searchSubjects(searchQuery: ISubjectSearch, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.post<ISubjectInfo[]>(
        `${apiUrl}/v1/aggregation/subject/search?offset=${offset}&limit=${limit}`,
        searchQuery,
      );
    }
    return axios.post<ISubjectInfo[]>(
      `${apiUrl}/v1/hub/aggregation/subject/search?offset=${offset}&limit=${limit}`,
      searchQuery,
    );
  },

  async addSubject(subject: ISubjectCreate) {
    return axios.post<ISubject>(
      `${apiUrl}/v1/aggregation/subject`,
      subject,
    );
  },

  async deleteSubject(subjectID: string) {
    return axios.delete<void>(
      `${apiUrl}/v1/aggregation/subject/${subjectID}`,
    );
  },

  async getSubjectContainers(subjectID: string, offset = 0, limit = 100) {
    if (getIsLoggedIn()) {
      return axios.get<IContainer[]>(
        `${apiUrl}/v1/aggregation/subject/${subjectID}/containers?offset=${offset}&limit=${limit}`,
      );
    }

    return axios.get<IContainer[]>(
      `${apiUrl}/v1/hub/aggregation/subject/${subjectID}/containers?offset=${offset}&limit=${limit}`,
    );
  },
};

export const aggregationAPI = {
  ...subjectAggregationAPI,
};

export default aggregationAPI