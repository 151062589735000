// import { ActionContext } from "vuex";
// import { State } from "@/store/state";
// import { StatsState } from "@/store/stats/state";
// import { getStoreAccessors } from "typesafe-vuex";

// type StatsContext = ActionContext<StatsState, State>;

export const actions = {

}

// const { dispatch } = getStoreAccessors<StatsState | any, State>("");