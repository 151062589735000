import api from "@/api";
import { extend } from "vee-validate";

extend("uniq_container_subject", {
  params: ["value", "container"],
  validate: async (value, { container }: IVeeValidateParameter) => {
    if (!value) {
      return false;
    }

    try {
      const response = await api.searchContainerSubjects(
        container?.owner.username, container?.name, { subject: value, approximate: false }
      );
      if (response.status === 204) {
        return true;
      }
      return false;
    } catch (error) {
      return true
    }

    return true;
  },
  message: "{_field_} is already in the container",
  computesRequired: true,
});


extend("uniq_stashed_container_subject", {
  params: ["value", "container"],
  validate: async (value, { container }: IVeeValidateParameter) => {
    if (!value) {
      return false;
    }

    try {
      const response = await api.searchContainerStashedSubjects(
        container?.owner.username, container?.name, { subject: value, approximate: false }
      );

      if (response.status === 204) {
        return true;
      }
      return false;
    } catch (error) {
      return true
    }

    return true;
  },
  message: "{_field_} is already stashed in the container",
  computesRequired: true,
});

extend("uniq_ignored_container_subject", {
  params: ["value", "container"],
  validate: async (value, { container }: IVeeValidateParameter) => {
    if (!value) {
      return false;
    }

    try {
      const response = await api.searchContainerIgnoredSubjects(container?.owner.username, container?.name, { subject: value, approximate: false });
      if (response.status === 204) {
        return true;
      }
      return false;
    } catch (error) {
      return true
    }

    return true;
  },
  message: "{_field_} is already ignored in the container",
  computesRequired: true,
});
