import { extend } from "vee-validate";

extend("valid_http_url", {
  validate: (value) => {
    const regex = new RegExp(
      /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/gm
    );

    return regex.test(value);
  },
  message: "{_value_} is not a valid HTTP/S URL.",
});
