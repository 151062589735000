import { State } from "@/store/state"
import { UserState } from "@/store/user/state"
import { getStoreAccessors } from "typesafe-vuex"

export const getters = {
  users: (state: UserState) => state.users,
  usersTotal: (state: UserState) => state.usersTotal,
  userData: (state: UserState) => state.uData,
  userContainers: (state: UserState) => state.uContainers,
  userContainersTotal: (state: UserState) => state.uContainersTotal
}

const { read } = getStoreAccessors<UserState, State>("");

export const readUsers = read(getters.users);
export const readUsersTotal = read(getters.usersTotal);
export const readUserContainers = read(getters.userContainers);
export const readUserContainersTotal = read(getters.userContainersTotal);
export const readUserData = read(getters.userData);