import { AuthState } from "@/store/auth/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
  isLoggedIn: (state: AuthState) => state.isLoggedIn,
  loginError: (state: AuthState) => state.loginError,
  passwordRecoveryError: (state: AuthState) => state.passwordRecoveryError,
  passwordResetError: (state: AuthState) => state.passwordResetError,
  loggedInUser: (state: AuthState) => state.loggedInUser,
  isSiteAdmin: (state: AuthState, acceptInactive = false): boolean => {
    if (!state.loggedInUser) {
      return false;
    }

    if (!state.loggedInUser.active) {
      if (acceptInactive) {
        return state.loggedInUser.site_admin;
      }
      return false;
    }

    return state.loggedInUser.site_admin;
  },
  loggedInUserTokens: (state: AuthState) => state.loggedInUserTokens,
  loggedInUserMachines: (state: AuthState) => state.loggedInUserMachines,
  loggedInUserMachinesTotal: (state: AuthState) => state.loggedInUserMachinesTotal,
  loggedInUserScopes: (state: AuthState) => state.loggedInUserScopes,
  loggedInUserContainers: (state: AuthState) => state.loggedInUserContainers,
  loggedInUserContainersTotal: (state: AuthState) => state.loggedInUserContainersTotal,
};

const { read } = getStoreAccessors<AuthState, State>("");

export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readPasswordRecoveryError = read(getters.passwordRecoveryError);
export const readPasswordResetError = read(getters.passwordResetError);
export const readLoggedInUser = read(getters.loggedInUser);
export const readIsSiteAdmin = read(getters.isSiteAdmin)
export const readLoggedInUserTokens = read(getters.loggedInUserTokens);
export const readLoggedInUserMachines = read(getters.loggedInUserMachines);
export const readLoggedInUserMachinesTotal = read(getters.loggedInUserMachinesTotal);
export const readLoggedInUserScopes = read(getters.loggedInUserScopes);
export const readLoggedInUserContainers = read(getters.loggedInUserContainers);
export const readLoggedInUserContainersTotal = read(getters.loggedInUserContainersTotal);