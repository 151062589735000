import api from "@/api";
import axios from "axios";
import { extend } from "vee-validate";

extend("uniq_username", {
    params: ["actual_username"],
    validate: async (value, { actual_username }: IVeeValidateParameter) => {
        if (value === actual_username) {
            return true;
        }

        if (!value) {
            return false;
        }

        try {
            await api.checkUserUsername(value);

            return false;
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 400) {
                return false;
            }
            return true;
        }
    },
    message: "{_field_} is already taken",
});