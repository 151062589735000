import { ExploreState } from "@/store/explore/state";
import { actions } from "@/store/explore/actions";
import { getters } from "@/store/explore/getters";
import { mutations } from "@/store/explore/mutations";

const defaultState: ExploreState = {

}

export const exploreModule = {
    // namespaced: true,
    state: defaultState,
    mutations,
    actions,
    getters,
};
