import { DashboardState } from "@/store/dashboard/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const getters = {
  passwordUpdateError: (state: DashboardState) => state.passwordUpdateError,
  usernameUpdateError: (state: DashboardState) => state.usernameUpdateError,
};

const { read } = getStoreAccessors<DashboardState, State>("");

export const readPasswordUpdateError = read(getters.passwordUpdateError);
export const readUsernameUpdateError = read(getters.usernameUpdateError);