const exploreRoutes = {
  path: "/explore",
  component: () =>
    import(/* webpackChunkName: "explore" */ "../views/explore/StartView.vue"),
  children: [
    {
      path: "",
      name: "explore",
      component: () =>
        import(
          /* webpackChunkName: "explore-home" */ "../views/explore/HomeView.vue"
        ),
    },
    {
      path: "subjects",
      component: () =>
        import(
          /* webpackChunkName: "explore-subjects-home" */ "../views/explore/subjects/StartView.vue"
        ),
      children: [
        {
          path: ":id",
          name: "explore-subjects",
          component: () =>
            import(
              /* webpackChunkName: "explore-subjects" */ "../views/explore/subjects/HomeView.vue"
            ),
        },
      ]
    },
  ]
}

export default exploreRoutes;