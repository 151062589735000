import { extend } from "vee-validate";
import { reservedUsernameExists } from "@/system/reservedUser";

extend("uniq_reserved_username", {
  params: ["actual_name"],
  validate: async (value, { actual_name }: IVeeValidateParameter) => {
    if (value === actual_name) {
      return true;
    }

    if (!value) {
      return false;
    }

    return !await reservedUsernameExists(value);
  },
  message: "{_value_} is already reserved",
});
