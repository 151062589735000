import { IContainer, IContainerSearch } from "@/interfaces/container";
import { IMachine, IMachineUpdate } from "@/interfaces/machine";
import { IUser, IUserCreate, IUserSearch, IUserUpdate } from "@/interfaces/user";
import { commitAddNotification, commitRemoveNotification } from "@/store/main/mutations";
import { commitRemoveUser, commitSetUserContainers, commitSetUserContainersTotal, commitSetUserData, commitSetUsers, commitSetUsersTotal } from "@/store/user/mutations";

import { ActionContext } from "vuex";
import { AxiosResponse } from "axios";
import { IRoleMinimal } from "@/interfaces/role";
import { State } from "@/store/state";
import { UserState } from "@/store/user/state";
import api from "@/api";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type UserContext = ActionContext<UserState, State>;

export const actions = {
  async actionGetUsers(context: UserContext, { offset = 0, limit = 100 }) {
    try {
      const response = await api.getUsers(
        offset,
        limit
      );

      if (response.data) {
        commitSetUsers(context, response.data);
        commitSetUsersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetUsers(context, []);
        commitSetUsersTotal(context, 0)
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetUserOwnedContainers(context: UserContext, { username = "", offset = 0, limit = 100 }) {
    try {
      let response: AxiosResponse

      if (username === context.rootState.auth.loggedInUser?.username) {
        response = await api.getMeOwnedContainers(offset, limit)
      } else {
        response = await api.getUserOwnedContainers(
          username
        )
      }

      if (response.data) {
        commitSetUserContainers(context, response.data)
        commitSetUserContainersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)));
      } else {
        commitSetUserContainers(context, [])
        commitSetUserContainersTotal(context, 0)
      }

    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
  },

  async actionAddUser(context: UserContext, user: IUserCreate) {
    const loadingNotification = {
      content: "Adding user...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addUser(user);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully added the @${user.username} user.`,
        color: "success",
      });
      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionUpdateUser(
    context: UserContext,
    info: { user: IUser; updated: IUserUpdate }
  ) {
    const loadingNotification = {
      content: "Updating user...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateUser(
        info.user.username,
        info.updated
      )

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated the @${info.user.username} user.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null
  },

  async actionUpdateUserScopes(
    context: UserContext,
    info: { user: IUser; scopes: Record<string, unknown> }
  ) {
    const loadingNotification = {
      content: "Updating user scopes...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateUserScopes(
        info.user.username,
        info.scopes
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully updated the scopes of the @${info.user.username} user.`,
        color: "success",
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null
  },

  async actionUpdateUserRoles(
    context: UserContext,
    info: { user: IUser; roles: IRoleMinimal[] }
  ) {
    const loadingNotification = {
      content: "Updating user roles...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    const results = [];

    const knownRole: IRoleMinimal[] = info.user.roles;
    const incomingRole: IRoleMinimal[] = info.roles;

    const deleteRole = knownRole.filter(x => !incomingRole?.includes(x));
    const newRole = incomingRole.filter(x => !knownRole?.includes(x));

    for (const role of deleteRole) {
      try {
        await api.revokeUserRole(
          info.user.username,
          role.id
        );

        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: `Successfully revoked the '${role.name}' role.`,
          color: "success",
        });
      } catch (error) {
        await dispatchCheckAPIError(context, error);
      }
    }

    for (const role of newRole) {
      try {
        const response = await api.assignUserRole(
          info.user.username,
          role.id
        );

        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, {
          content: `Successfully assigned the '${role.name}' role.`,
          color: "success",
        });
        results.push(response.data)
      } catch (error) {
        await dispatchCheckAPIError(context, error);
      }
    }

    commitRemoveNotification(context, loadingNotification);

    return results
  },


  async actionDeleteUser(context: UserContext, user: IUser) {
    const loadingNotification = {
      content: "Deleting user...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await Promise.all([
        api.deleteUser(user.username),
        await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
      ])

      commitRemoveNotification(context, loadingNotification);
      await commitRemoveUser(context, user);
      commitAddNotification(context, {
        content: `Successfully deleted the @${user.username} user.`,
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionSearchUsers(context: UserContext, { searchQuery = "", approximate = false, offset = 0, limit = 100 }) {
    const query: IUserSearch = { approximate: approximate, username: searchQuery }

    try {
      const response = await api.searchUsers(query, offset, limit);
      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }

    return []
  },

  async actionSearchUserContainers(context: UserContext, { searchQuery = "", approximate = true }) {
    const query: IContainerSearch = { approximate: approximate, name: searchQuery, display_name: searchQuery }

    try {
      const response = await api.getMeContainers(query);
      return response.data;
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }

    return []
  },

  async actionGetUsersTotal(context: UserContext) {
    try {
      const response = await api.getUserStats(
      );

      if (response.status === 200) {
        commitSetUsersTotal(context, parseInt(response.headers["x-total"]));
      } else {
        commitSetUsersTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionGetUserData(context: UserContext, username: string) {
    if (username !== context.rootState.auth.loggedInUser?.username) {
      try {
        const response = await api.getUser(
          username
        );

        if (response?.data) {
          commitSetUserData(context, response.data);
        } else {
          commitSetUserData(context, null);
        }
      } catch (error) {
        await dispatchCheckAPIError(context, error);
      }
    } else {
      commitSetUserData(context, context.rootState.auth.loggedInUser);
    }
  },

  async actionAssociateMeMachineToContainer(
    context: UserContext,
    info: { machine: IMachine, containers: IContainer[]; }
  ) {
    const loadingNotification = {
      content: "Associating machine to containers...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      await api.associateMeMachineToContainer(
        info.machine.id,
        { containers: info.containers.map(x => x.id) }
      );

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully associated containers to the machine/${info.machine.machinename}.`,
        color: "success",
      });

      return true;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null;
  },

  async actionUpdateMeMachineMachinename(
    context: UserContext,
    info: { machine: IMachine, machinename: IMachineUpdate; }
  ) {
    const loadingNotification = {
      content: "Updating the machine's name...",
      color: "info",
      showProgress: true,
    };
    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateMeMachineMachinename(info.machine.id, info.machinename);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Successfully updated your machine's name.",
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },

  async actionUpdateMeMachineTestOwnerContainer(
    context: UserContext,
    info: { machine: IMachine, testOwnerContainer: IMachineUpdate; }
  ) {
    const loadingNotification = {
      content: "Updating the machine's informations...",
      color: "info",
      showProgress: true,
    };
    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.updateMeMachineTestOwnerContainer(info.machine.id, info.testOwnerContainer);

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Successfully updated your machine's informations.",
        color: "success",
      });

      return response.data;
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }

    return null;
  },
}

const { dispatch } = getStoreAccessors<UserContext | any, State>("");

export const dispatchSearchUsers = dispatch(actions.actionSearchUsers);
export const dispatchAddUser = dispatch(actions.actionAddUser);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchUpdateUserScopes = dispatch(actions.actionUpdateUserScopes);
export const dispatchUpdateUserRoles = dispatch(actions.actionUpdateUserRoles);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchGetUsersTotal = dispatch(actions.actionGetUsersTotal);

export const dispatchGetUserData = dispatch(actions.actionGetUserData);

export const dispatchGetUserOwnedContainers = dispatch(actions.actionGetUserOwnedContainers);
export const dispatchSearchUserContainers = dispatch(actions.actionSearchUserContainers);

export const dispatchAssociateMeMachineToContainer = dispatch(actions.actionAssociateMeMachineToContainer);
export const dispatchUpdateMeMachineMachinename = dispatch(actions.actionUpdateMeMachineMachinename);
export const dispatchUpdateMeMachineTestOwnerContainer = dispatch(actions.actionUpdateMeMachineTestOwnerContainer);