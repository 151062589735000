import { IUser } from "@/interfaces/user";

import { IContainer } from "@/interfaces/container";
import { State } from "@/store/state"
import { UserState } from "@/store/user/state"
import { getStoreAccessors } from "typesafe-vuex"

export const mutations = {
  setUsers(state: UserState, payload: IUser[]) {
    state.users = payload;
  },
  addUser(state: UserState, payload: IUser) {
    const users = state.users.filter((user: IUser) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  removeUser(state: UserState, payload: IUser) {
    const users = state.users.filter((user: IUser) => user.id !== payload.id);
    state.users = users;
  },
  setUsersTotal(state: UserState, payload: number) {
    // TODO: Add guard
    state.usersTotal = payload
  },
  setUserContainers(state: UserState, payload: IContainer[]) {
    state.uContainers = payload
  },
  addUserContainer(state: UserState, payload: IContainer) {
    const containers = state.uContainers.filter((container: IContainer) => container.id !== payload.id);
    containers.push(payload)
    state.uContainers = containers;
  },
  removeUserContainer(state: UserState, payload: IContainer) {
    const containers = state.uContainers.filter((container: IContainer) => container.id !== payload.id);
    state.uContainers = containers;
  },
  setUserContainersTotal(state: UserState, payload: number) {
    state.uContainersTotal = payload
  },
  setUserData(state: UserState, payload: IUser | null) {
    state.uData = payload
  }
}

const { commit } = getStoreAccessors<UserState | any, State>("");

export const commitSetUsers = commit(mutations.setUsers);
export const commitAddUser = commit(mutations.addUser);
export const commitRemoveUser = commit(mutations.removeUser);
export const commitSetUsersTotal = commit(mutations.setUsersTotal);

export const commitSetUserContainers = commit(mutations.setUserContainers);
export const commitAddUserContainer = commit(mutations.addUserContainer);
export const commitRemoveUserContainer = commit(mutations.removeUserContainer);
export const commitSetUserContainersTotal = commit(mutations.setUserContainersTotal);

export const commitSetUserData = commit(mutations.setUserData);
