import { commitAddNotification, commitRemoveNotification } from "../main/mutations";
import { commitRemoveMachine, commitSetMachineTotal, commitSetMachines } from "@/store/machine/mutations";

import { ActionContext } from "vuex";
import { IMachine } from "@/interfaces/machine";
import { MachineState } from "@/store/machine/state";
import { State } from "@/store/state";
import api from "@/api";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type MachineContext = ActionContext<MachineState, State>;

export const actions = {

  async actionGetMachines(context: MachineContext, { offset = 0, limit = 100 }) {
    try {
      const response = await api.getMachines(
        offset,
        limit
      );

      if (response.data) {
        commitSetMachines(context, response.data);
      } else {
        commitSetMachines(context, []);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

  async actionDeleteMachine(context: MachineContext, machine: IMachine) {
    const loadingNotification = {
      content: "Deleting machine...",
      color: "info",
      showProgress: true,
    };

    commitAddNotification(context, loadingNotification);

    try {
      (
        await Promise.all([
          api.deleteMachine(machine.id),
          await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
        ])
      )[0];

      commitRemoveNotification(context, loadingNotification);
      await commitRemoveMachine(context, machine);

      commitAddNotification(context, {
        content: `Successfully deleted the ${machine.machinename} machine.`,
        color: "success",
      });
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  },
  async actionGetMachinesTotal(context: MachineContext) {
    try {
      const response = await api.getMachineStats(
      );

      if (response.status === 200) {
        commitSetMachineTotal(context, parseInt(response.headers["x-total"]));
      } else {
        commitSetMachineTotal(context, 0);
      }
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
  },

}

const { dispatch } = getStoreAccessors<MachineContext | any, State>("");


export const dispatchGetMachines = dispatch(actions.actionGetMachines);
export const dispatchDeleteMachine = dispatch(actions.actionDeleteMachine);
export const dispatchGetMachinesTotal = dispatch(actions.actionGetMachinesTotal);
