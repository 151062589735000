import { Component, Emit, Mixins, Prop, VModel } from "vue-property-decorator"

import AppBase from "@/AppBase";
import { SubjectTypeEnum } from "./interfaces/aggregation/subject";

@Component
export default class ComponentBase extends Mixins(AppBase) {
  @VModel({ type: Boolean, default: false })
  public show!: boolean;

  @Prop({ type: Boolean, default: true })
  public readonly hideOnSuccess!: boolean;

  @Emit("hidden")
  public async hide() {
    // await new Promise((resolve) => setTimeout(() => resolve(true), 100));
    this.show = false;
  }

  @Emit("success")
  public spreadSuccess(data: any) {
    if (this.hideOnSuccess === true) {
      this.hide()
    }

    return data
  }

  public get subjectMinimalTypesItems() {
    const order = [
      SubjectTypeEnum.domain,
      SubjectTypeEnum.url,
    ];

    const result = [];

    for (let i = 0; i < order.length; i++) {
      result.push({
        label: this.prettySubjectType(order[i]),
        value: order[i],
      });
    }

    return result;
  }


  public get subjectTypesItems() {
    const order = [
      SubjectTypeEnum.domain,
      SubjectTypeEnum.url,
      SubjectTypeEnum.any,
    ];

    const result = [];

    for (let i = 0; i < order.length; i++) {
      result.push({
        label: this.prettySubjectType(order[i]),
        value: order[i],
      });
    }

    return result;
  }

}