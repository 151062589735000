import { DashboardState } from "@/store/dashboard/state";
import { actions } from "@/store/dashboard/actions";
import { getters } from "@/store/dashboard/getters";
import { mutations } from "@/store/dashboard/mutations";

const defaultState: DashboardState = {
  passwordUpdateError: false,
  usernameUpdateError: false,
};

export const dashboardModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
