import { IContainer, IContainerExportRule, IContainerRemoteSubject, IContainerSubject, RemoteSubjectLinkTypeEnum } from "@/interfaces/container";

import { ContainerState } from "@/store/container/state"
import { IUser } from "@/interfaces/user";
import { State } from "@/store/state"
import { getStoreAccessors } from "typesafe-vuex"
import { objectsEqual } from "@/utils/objectsManipulation";

export const mutations = {
  setLContainer(state: ContainerState, payload: IContainer | null) {
    state.lContainer = payload
  },
  setContainers(state: ContainerState, payload: IContainer[]) {
    state.containers = payload;
  },
  setContainerContributors(state: ContainerState, payload: { container: IContainer, contributors: IUser[] }) {
    if (state.containers.length > 0) {
      const index = state.containers.findIndex(x => objectsEqual(x, payload.container));

      state.containers[index].contributors = payload.contributors;
    }

    if (state.lContainer && objectsEqual(state.lContainer, payload.container)) {
      state.lContainer.contributors = payload.contributors
    }
  },
  setContainerViewers(state: ContainerState, payload: { container: IContainer, viewers: IUser[] }) {
    if (state.containers.length > 0) {
      const index = state.containers.findIndex(x => JSON.stringify(x) === JSON.stringify(payload.container));

      state.containers[index].viewers = payload.viewers;
    }

    if (state.lContainer && objectsEqual(state.lContainer, payload.container)) {
      state.lContainer.viewers = payload.viewers
    }
  },
  addContainer(state: ContainerState, payload: IContainer) {
    const containers = state.containers.filter(
      (container: IContainer) => container.id !== payload.id
    );
    containers.push(payload);
    state.containers = containers;
  },
  removeContainer(state: ContainerState, payload: IContainer) {
    const containers = state.containers.filter(
      (container: IContainer) => container.id !== payload.id
    );
    state.containers = containers;
  },
  setContainersTotal(state: ContainerState, payload: number) {
    state.containersTotal = payload;
  },
  setLContainerExportRules(state: ContainerState, payload: IContainerExportRule[]) {
    state.lContainerExportRules = payload;
  },
  addLContainerExportRule(state: ContainerState, payload: IContainerExportRule) {
    const exportRules = state.lContainerExportRules.filter(
      (exportRule: IContainerExportRule) => exportRule.id !== payload.id
    );
    exportRules.push(payload);
    state.lContainerExportRules = exportRules;
  },
  removeLContainerExportRule(state: ContainerState, payload: IContainerExportRule) {
    const exportRules = state.lContainerExportRules.filter(
      (exportRule: IContainerExportRule) => exportRule.id !== payload.id
    );
    state.lContainerExportRules = exportRules;
  },
  setLContainerExportRulesTotal(state: ContainerState, payload: number) {
    state.lContainerExportRulesTotal = payload;
  },
  setLContainerRemoteSubjects(state: ContainerState, payload: IContainerRemoteSubject[]) {
    state.lContainerRemoteSubjects = payload;
  },
  addLContainerRemoteSubject(state: ContainerState, payload: IContainerRemoteSubject) {
    const remoteSubjects = state.lContainerRemoteSubjects.filter(
      (remoteSubject: IContainerRemoteSubject) => remoteSubject.id !== payload.id
    );
    remoteSubjects.push(payload);
    state.lContainerRemoteSubjects = remoteSubjects;
  },
  removeLContainerRemoteSubject(state: ContainerState, payload: IContainerRemoteSubject) {
    const remoteSubjects = state.lContainerRemoteSubjects.filter(
      (remoteSubject: IContainerRemoteSubject) => remoteSubject.id !== payload.id
    );
    state.lContainerRemoteSubjects = remoteSubjects;
  },
  setLContainerRemoteSubjectsTotal(state: ContainerState, payload: number) {
    state.lContainerRemoteSubjectsTotal = payload;
  },
  setLContainerAssociatedSubjects(state: ContainerState, payload: IContainerSubject[]) {
    state.lContainerAssociatedSubjects = payload;
  },
  addLContainerAssociatedSubject(state: ContainerState, payload: IContainerSubject) {
    const subjects = state.lContainerAssociatedSubjects.filter(
      (subject: IContainerSubject) => subject.id !== payload.id
    );
    subjects.push(payload);
    state.lContainerAssociatedSubjects = subjects;
  },
  removeLContainerAssociatedSubject(state: ContainerState, payload: IContainerSubject) {
    const subjects = state.lContainerAssociatedSubjects.filter(
      (subject: IContainerSubject) => subject.id !== payload.id
    );
    state.lContainerAssociatedSubjects = subjects;
  },
  setLContainerAssociatedSubjectsTotal(state: ContainerState, payload: number) {
    state.lContainerAssociatedSubjectsTotal = payload;
  },
  setLContainerIgnoredSubjects(state: ContainerState, payload: IContainerSubject[]) {
    state.lContainerIgnoredSubjects = payload;
  },
  addLContainerIgnoredSubject(state: ContainerState, payload: IContainerSubject) {
    const subjects = state.lContainerIgnoredSubjects.filter(
      (subject: IContainerSubject) => subject.id !== payload.id
    );
    subjects.push(payload);
    state.lContainerIgnoredSubjects = subjects;
  },
  removeLContainerIgnoredSubject(state: ContainerState, payload: IContainerSubject) {
    const subjects = state.lContainerIgnoredSubjects.filter(
      (subject: IContainerSubject) => subject.id !== payload.id
    );
    state.lContainerIgnoredSubjects = subjects;
  },
  setLContainerIgnoredSubjectsTotal(state: ContainerState, payload: number) {
    state.lContainerIgnoredSubjectsTotal = payload;
  },
  setLContainerStashedSubjects(state: ContainerState, payload: IContainerSubject[]) {
    state.lContainerStashedSubjects = payload;
  },
  addLContainerStashedSubject(state: ContainerState, payload: IContainerSubject) {
    const subjects = state.lContainerStashedSubjects.filter(
      (subject: IContainerSubject) => subject.id !== payload.id
    );
    subjects.push(payload);
    state.lContainerStashedSubjects = subjects;
  },
  removeLContainerStashedSubject(state: ContainerState, payload: IContainerSubject) {
    const subjects = state.lContainerStashedSubjects.filter(
      (subject: IContainerSubject) => subject.id !== payload.id
    );
    state.lContainerStashedSubjects = subjects;
  },
  setLContainerStashedSubjectsTotal(state: ContainerState, payload: number) {
    state.lContainerStashedSubjectsTotal = payload;
  },
  setLContainerSubjectLockedLink(state: ContainerState, payload: { subject: IContainerSubject, locked: boolean }) {
    if (payload.subject.link_type === RemoteSubjectLinkTypeEnum.associated) {
      const subjects = state.lContainerAssociatedSubjects.filter(
        (subject: IContainerSubject) => subject.id !== payload.subject.id
      );

      payload.subject.link_locked = payload.locked;
      subjects.push(payload.subject);
      state.lContainerAssociatedSubjects = subjects;
    } else if (payload.subject.link_type === RemoteSubjectLinkTypeEnum.ignored) {
      const subjects = state.lContainerIgnoredSubjects.filter(
        (subject: IContainerSubject) => subject.id !== payload.subject.id
      );

      payload.subject.link_locked = payload.locked;
      subjects.push(payload.subject);
      state.lContainerIgnoredSubjects = subjects;
    } else if (payload.subject.link_type === RemoteSubjectLinkTypeEnum.stashed) {
      const subjects = state.lContainerStashedSubjects.filter(
        (subject: IContainerSubject) => subject.id !== payload.subject.id
      );

      payload.subject.link_locked = payload.locked;
      subjects.push(payload.subject);
      state.lContainerStashedSubjects = subjects;
    }
  }
}

const { commit } = getStoreAccessors<ContainerState | any, State>("");


// TODO: better naming.
export const commitSetLContainer = commit(mutations.setLContainer);
export const commitSetLContainerExportRules = commit(mutations.setLContainerExportRules)
export const commitAddLContainerExportRule = commit(mutations.addLContainerExportRule)
export const commitRemoveLContainerExportRule = commit(mutations.removeLContainerExportRule)
export const commitSetLContainerExportRulesTotal = commit(mutations.setLContainerExportRulesTotal)

export const commitSetContainers = commit(mutations.setContainers);
export const commitSetContainerContributors = commit(mutations.setContainerContributors);
export const commitSetContainerViewers = commit(mutations.setContainerViewers);
export const commitAddContainer = commit(mutations.addContainer);
export const commitRemoveContainer = commit(mutations.removeContainer);
export const commitSetContainersTotal = commit(mutations.setContainersTotal);

export const commitSetLContainerRemoteSubjects = commit(mutations.setLContainerRemoteSubjects);
export const commitAddLContainerRemoteSubject = commit(mutations.addLContainerRemoteSubject);
export const commitRemoveLContainerRemoteSubject = commit(mutations.removeLContainerRemoteSubject);
export const commitSetLContainerRemoteSubjectsTotal = commit(mutations.setLContainerRemoteSubjectsTotal);

export const commitSetLContainerAssociatedSubjects = commit(mutations.setLContainerAssociatedSubjects);
export const commitAddLContainerAssociatedSubject = commit(mutations.addLContainerAssociatedSubject);
export const commitRemoveLContainerAssociatedSubject = commit(mutations.removeLContainerAssociatedSubject);
export const commitSetLContainerAssociatedSubjectsTotal = commit(mutations.setLContainerAssociatedSubjectsTotal);

export const commitSetLContainerIgnoredSubjects = commit(mutations.setLContainerIgnoredSubjects);
export const commitAddLContainerIgnoredSubject = commit(mutations.addLContainerIgnoredSubject);
export const commitRemoveLContainerIgnoredSubject = commit(mutations.removeLContainerIgnoredSubject);
export const commitSetLContainerIgnoredSubjectsTotal = commit(mutations.setLContainerIgnoredSubjectsTotal);

export const commitSetLContainerStashedSubjects = commit(mutations.setLContainerStashedSubjects);
export const commitAddLContainerStashedSubject = commit(mutations.addLContainerStashedSubject);
export const commitRemoveLContainerStashedSubject = commit(mutations.removeLContainerStashedSubject);
export const commitSetLContainerStashedSubjectsTotal = commit(mutations.setLContainerStashedSubjectsTotal);

export const commitSetLContainerSubjectLockedLink = commit(mutations.setLContainerSubjectLockedLink);