import { Component, Vue } from "vue-property-decorator";
import { IUser, IUserMinimal, PreferredStyleEnum } from "./interfaces/user";
import { assetsUrl, defaultTheme } from "./consts";
import { commitAddNotification, commitSetDisplayContainerFillHeight, commitSetShowNewContainer } from "@/store/main/mutations";
import { dispatchGetCurrentUser, dispatchUserLogout } from "./store/auth/actions";
import { readDisplayContainerFillHeight, readShowAppBar, readShowNewContainer, readShowNotAuthorized, readShowNotFound, readSystemSettings } from "./store/main/getters";
import { readIsLoggedIn, readIsSiteAdmin, readLoggedInUser } from "@/store/auth/getters";

import { IContainer } from "./interfaces/container";
import { ISubjectInfo } from "@/interfaces/aggregation/subject";
import { dispatchUpdateMePreferredStyle } from "./store/dashboard/actions";

@Component
export default class AppBase extends Vue {
  avatarFailed = null;

  public get showAppBar() {
    if (this.isLoggedIn === null || this.systemSettings === null) {
      return false;
    }

    return readShowAppBar(this.$store);
  }

  public get showLoader() {
    return this.isLoggedIn === null || this.systemSettings === null;
  }

  public get showNotAuthorized() {
    return readShowNotAuthorized(this.$store);
  }

  public get showNotFound() {
    return readShowNotFound(this.$store);
  }

  public get showNewContainer() {
    return readShowNewContainer(this.$store);
  }

  public set showNewContainer(value: boolean) {
    commitSetShowNewContainer(this.$store, value);
  }

  public get displayContainerFillHeight() {
    return readDisplayContainerFillHeight(this.$store);
  }

  public set displayContainerFillHeight(value: boolean) {
    commitSetDisplayContainerFillHeight(this.$store, value);
  }

  public get systemSettings() {
    return readSystemSettings(this.$store);
  }


  public get loggedInUser() {
    return readLoggedInUser(this.$store) || {} as IUser;
  }

  public get darkThemeActive() {
    return this.loggedInUser?.settings?.preferred_style === PreferredStyleEnum.dark;
  }
  public get username() {
    return this.loggedInUser?.username || "john.doe";
  }

  public get displayName() {
    return this.loggedInUser?.name || this.username;
  }

  public get defaultAvatar() {
    return `${assetsUrl}/avatars/default`;
  }

  public get avatar_url() {
    if (this.avatarFailed) {
      return this.defaultAvatar;
    }
    return this.loggedInUser?.avatar_url || this.defaultAvatar;
  }

  public userTheme() {
    if (this.isLoggedIn) {
      return readLoggedInUser(this.$store)?.settings?.preferred_style
    }
    return defaultTheme;
  }

  public get isLoggedIn() {
    return readIsLoggedIn(this.$store);
  }

  public get isSiteAdmin() {
    if (!this.isLoggedIn) {
      return false;
    }

    return readIsSiteAdmin(this.$store);
  }

  public isUserSiteAdmin(user: IUser | null) {
    return user?.site_admin === true;
  }

  public get isTrustedUser() {
    if (!this.isLoggedIn) {
      return false;
    }

    return this.loggedInUser?.trusted;
  }

  public hasScope(scope: string): boolean {
    if (!this.isLoggedIn) {
      return false;
    }


    const scopes = readLoggedInUser(this.$store)?.scopes;

    if (!scopes) {
      return this.isSiteAdmin;
    }

    if (this.isSiteAdmin) {
      return true
    }

    return scopes[scope] ? true : false;
  }

  public isContainerOwner(container: IContainer, user: IUser | null) {
    if (this.isUserSiteAdmin(user)) {
      return true;
    }

    return user?.username === container.owner?.username;
  }

  public isContainerContributor(container: IContainer, user: IUser | null) {
    if (this.isContainerOwner(container, user)) {
      return true;
    }

    if (user?.username && container?.contributors) {
      for (const contributor in container.contributors) {
        if (container.contributors[contributor]?.username === user.username) {
          return true;
        }
      }
    }
    return false;
  }

  public calculateTestedPercentage(item: IContainer, statType: string) {
    const totalSubjects = item.subjects_count;
    let testedSubjects = 0;

    if (statType === "availability") {
      testedSubjects = item.availability_tested_count;
    } else if (statType === "syntax") {
      testedSubjects = item.syntax_tested_count;
    } else if (statType === "reputation") {
      testedSubjects = item.reputation_tested_count;
    }

    return totalSubjects === 0
      ? 0
      : Math.round((testedSubjects / totalSubjects) * 100);
  }

  public hasScopes(scopes: string[]): boolean {
    const scope_match = scopes.map(scope => this.hasScope(scope));

    return scope_match.some((x) => x === true);
  }

  public hasScopesStrict(scopes: string[]): boolean {
    const scope_match = scopes.map(scope => this.hasScope(scope));

    return scope_match.every((x) => x === true);
  }
  public async logoutUser() {
    await dispatchUserLogout(this.$store);
  }

  public colorFromStatus(status: string) {
    if (status) {
      status = status?.toLowerCase();

      if (status === "invalid") {
        return "cyan";
      }

      if (status === "valid" || status === "sane" || status === "active") {
        return "green";
      }

      if (status === "malicious" || status === "inactive") {
        return "red";
      }
    }

    return "#66380e";
  }

  public colorFromTestMethod(testMethod: string) {
    testMethod = testMethod?.toLowerCase();

    if (testMethod === "syntax") {
      return "#536ffc"
    }

    if (testMethod === "availability") {
      return "#ff9205"
    }

    if (testMethod === "reputation") {
      return "pink"
    }

    return "#66380e";
  }

  public prettyInputFormat(inputFormat: string) {
    if (inputFormat === "adblock") {
      return "AdBlock Filter List";
    }

    if (inputFormat === "host") {
      return "Hosts File";
    }

    if (inputFormat === "plain") {
      return "Plain Text";
    }

    return "Unknown Input Format";
  }

  public prettyLinkType(linkType: string) {
    if (linkType === "associated") {
      return "Associate";
    }

    if (linkType === "ignored") {
      return "Ignore";
    }

    if (linkType === "stashed") {
      return "Stash";
    }

    return "Unknown Link Type";
  }

  public prettySubjectType(subjectType: string) {
    if (subjectType === "domain") {
      return "Domain & IP";
    }

    if (subjectType === "url") {
      return "URL";
    }

    if (subjectType === "any") {
      return "Domain, IP & URL";
    }

    return "Unknown Subject Type";
  }

  public prettySubjectTypeColor(subjectType: string) {
    if (subjectType === "domain") {
      return "rgb(7, 120, 60)";
    }

    if (subjectType === "url") {
      return "rgb(7, 120, 60)";
    }

    if (subjectType === "any") {
      return "rgb(7, 120, 60)";
    }

    return "grey darken-3";
  }


  public addToClipboard(data: string) {
    navigator.clipboard.writeText(data);


    commitAddNotification(this.$store, {
      content: "Copied into Clipboard",
      color: "cyan",
    });
  }

  public openURL(url: string) {
    window.open(url, "_blank")?.focus();
  }

  public exploreHome() {
    this.$router.push({
      name: "explore",
    });
  }

  public exploreAccount(dataset: IUser | IUserMinimal) {
    this.$router.push({
      name: "account",
      params: {
        username: dataset.username,
      },
    });
  }

  public exploreSubject(dataset: ISubjectInfo, items: any = null) {
    if (items !== null && items.item !== undefined) {
      this.$router.push({
        name: "explore-subjects",
        params: {
          id: items.item.id,
        },
      });
    } else {

      this.$router.push({
        name: "explore-subjects",
        params: {
          id: dataset.id,
        },
      });
    }
  }

  public exploreContainer(dataset: IContainer, items: any = null) {
    if (items !== null && items.item !== undefined) {
      this.$router.push({
        name: "account-container",
        params: {
          username: items.item.owner.username,
          container: items.item.name,
        },
      });
    } else if (dataset.name && dataset.owner?.username) {
      this.$router.push({
        name: "account-container",
        params: {
          username: dataset.owner?.username,
          container: dataset.name,
        },
      });
    } else {
      commitAddNotification(this.$store, {
        content: `Couldn't find Container.\nMissing name.\n\nDetails ${JSON.stringify(dataset)}`,
        color: "red",
      });
    }
  }

  public avatarFromUser(dataset: IUser) {
    if (this.avatarFailed || !dataset?.avatar_url) {
      return this.defaultAvatar;
    }
    return dataset.avatar_url;
  }

  public async switchTheme() {
    const userTheme = this.userTheme();
    let newStyle: PreferredStyleEnum

    if (userTheme === PreferredStyleEnum.dark) {
      newStyle = PreferredStyleEnum.light;
    } else if (userTheme === PreferredStyleEnum.light) {
      newStyle = PreferredStyleEnum.dark;
    } else {
      newStyle = PreferredStyleEnum.dark;
    }

    await dispatchUpdateMePreferredStyle(this.$store, newStyle);

    this.$vuetify.theme.dark = newStyle === PreferredStyleEnum.dark;

    await dispatchGetCurrentUser(this.$store);
  }


}