import { IRoleMinimal } from "@/interfaces/role";

export interface IUserScopes {
  [key: string]: boolean;
}

export enum PreferredStyleEnum {
  dark = "dark",
  light = "light"
}

export interface IUserSettings {
  preferred_style?: PreferredStyleEnum
}

export interface IUserMinimal {
  id: string;
  username: string;
  bio: string;
  avatar_url: string | null;

  site_admin: boolean;
  trusted: boolean;
}

export interface IUser {
  email: string;
  username: string;
  name?: string | null;
  active: boolean;
  pending_approval?: boolean;
  site_admin: boolean;
  trusted: boolean;
  id: string;
  avatar_url: string | null;
  github_username: string | null;
  github_user_id: string | null;
  gitlab_username: string | null;
  gitlab_user_id: string | null;
  scopes: IUserScopes;
  roles: IRoleMinimal[];
  settings?: IUserSettings;
  bio: string;
}

export interface IUserUpdate {
  email?: string;
  username?: string;
  name?: string | null;
  password?: string;
  active?: boolean;
  pending_approval?: boolean;
  site_admin?: boolean;
  trusted?: boolean;
  bio?: string;
}

export interface IUserUpdatePassword {
  current_password: string;
  new_password: string;
}

export interface IUserUpdateUsername {
  username: string
}

export interface IUserUpdateBio {
  bio: string
}

export interface IUserCreate {
  email: string;
  username: string;
  password?: string;
  active?: boolean;
  site_admin?: boolean;
  trusted?: boolean;
}

export interface IUserSearch {
  username: string;
  approximate?: boolean
}