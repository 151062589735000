import { SubjectState } from "@/store/subject/state";
import { actions } from "@/store/subject/actions";
import { getters } from "@/store/subject/getters";
import { mutations } from "@/store/subject/mutations";

const defaultState: SubjectState = {
  subjects: [],
  subjectsTotal: 0,
  subjectContainers: [],
  subjectContainersTotal: 0
}

export const subjectModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters
}