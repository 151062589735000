import { DashboardState } from "@/store/dashboard/state";
import { State } from "@/store/state";
import { getStoreAccessors } from "typesafe-vuex";

export const mutations = {
  setPasswordUpdateError(state: DashboardState, payload: boolean) {
    state.passwordUpdateError = payload;
  },
  setUsernameUpdateError(state: DashboardState, payload: boolean) {
    state.passwordUpdateError = payload;
  },

};

const { commit } = getStoreAccessors<DashboardState, State>("");

export const commitSetPasswordUpdateError = commit(
  mutations.setPasswordUpdateError
);
export const commitSetUsernameUpdateError = commit(mutations.setUsernameUpdateError);