const notFoundRoutes = [
    {
        path: '*',
        name: 'not-found',
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
    },
    {
        path: '404',
        name: 'not-found-404',
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/NotFound.vue"),
    }

]

export default notFoundRoutes