import "@/component-hooks";
import "@/registerServiceWorker";
import "@/plugins/vee-validate";
import "@/plugins/vue-country-flag";
import "@/plugins/dayjs";
import "@/plugins/directives";
import "@/assets/css/scroller.css";

import { commitSetShowNotAuthorized, commitSetShowNotFound } from "./store/main/mutations";

import App from "@/App.vue";
import Vue from "vue";
import axios from "axios";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";

Vue.config.productionTip = false;

axios.defaults.withCredentials = true

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");


router.afterEach(async () => {
  await commitSetShowNotAuthorized(store, false);
  await commitSetShowNotFound(store, false);
})