import { api } from "@/api";

export const roleNameExists = async (name: string): Promise<boolean> => {
    try {
        const response = await api.checkRoleName(name);

        return response.status == 200;
    } catch (error) {
        return false;
    }
};