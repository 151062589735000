import api from "@/api";
import { AxiosError } from "axios";
import { extend } from "vee-validate";


extend("uniq_container_name", {
  params: ["actual_owner", "actual_name"],
  validate: async (value, { actual_owner, actual_name }: IVeeValidateParameter) => {
    if (value === actual_name) {
      return true;
    }

    if (!value) {
      return false;
    }

    try {
      await api.checkContainerName(actual_owner, value);
      return false;
    } catch (error) {
      if (api.isAxiosError(error) && (error as AxiosError).response?.status === 400) {
        return false;
      }
      return true;
    }
  },
  message: "{_field_} is already taken",
});
