// import { ExploreState } from "@/store/explore/state";
// import { State } from "@/store/state";
// import { getStoreAccessors } from "typesafe-vuex";

export const getters = {

}

// const { read } = getStoreAccessors<ExploreState, State>("");

