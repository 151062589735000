import { ISubject, ISubjectInfo } from "@/interfaces/aggregation/subject";

import { IContainer } from "@/interfaces/container";
import { State } from "@/store/state"
import { SubjectState } from "@/store/subject/state"
import { getStoreAccessors } from "typesafe-vuex"

export const mutations = {
  setSubjects(state: SubjectState, payload: ISubjectInfo[] | ISubject[]) {
    state.subjects = payload;
  },
  addSubject(state: SubjectState, payload: ISubjectInfo | ISubject) {
    const subjects = state.subjects.filter(
      (subject: ISubjectInfo | ISubject) => subject.id !== payload.id
    );
    subjects.push(payload);
    state.subjects = subjects;
  },
  removeSubject(state: SubjectState, payload: ISubjectInfo | ISubject) {
    const subjects = state.subjects.filter(
      (subject: ISubjectInfo | ISubject) => subject.id !== payload.id
    );

    state.subjects = subjects;
  },
  setSubjectsTotal(state: SubjectState, payload: number) {
    state.subjectsTotal = payload
  },
  setSubjectContainers(state: SubjectState, payload: IContainer[]) {
    state.subjectContainers = payload;
  },
  addSubjectContainer(state: SubjectState, payload: IContainer) {
    const containers = state.subjectContainers.filter(
      (container: IContainer) => container.id !== payload.id
    );
    containers.push(payload);
    state.subjectContainers = containers;
  },
  removeSubjectContainer(state: SubjectState, payload: IContainer) {
    const containers = state.subjectContainers.filter(
      (container: IContainer) => container.id !== payload.id
    );

    state.subjectContainers = containers;
  },
  setSubjectContainerTotal(state: SubjectState, payload: number) {
    state.subjectContainersTotal = payload
  }
}

const { commit } = getStoreAccessors<SubjectState | any, State>("");

export const commitSetSubjects = commit(mutations.setSubjects);
export const commitAddSubject = commit(mutations.addSubject);
export const commitRemoveSubject = commit(mutations.removeSubject);
export const commitSetSubjectsTotal = commit(mutations.setSubjectsTotal);

export const commitSetSubjectContainers = commit(mutations.setSubjectContainers);
export const commitAddSubjectContainer = commit(mutations.addSubjectContainer);
export const commitRemoveSubjectContainer = commit(mutations.removeSubjectContainer);
export const commitSetSubjectContainersTotal = commit(mutations.setSubjectContainerTotal);
