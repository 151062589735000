import VueRouter, { RouteConfig } from "vue-router";

import Vue from "vue";
import VueMeta from 'vue-meta';
import accountRoutes from "./account";
import adminRoutes from "./admin";
import authRoutes from "./auth";
import dashboardRoutes from "./dashboard";
import exploreRoutes from "./explore";
import newRoutes from "./new"
import notFoundRoutes from "./notFound";
import settingsRoutes from "./settings";

Vue.use(VueRouter);
Vue.use(VueMeta)

const routes: Array<RouteConfig> = [
  {
    path: "/",

    component: () =>
      import(/* webpackChunkName: "start" */ "../views/StartView.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/explore/HomeView.vue"),
      },
      authRoutes,
      dashboardRoutes,
      exploreRoutes,
      adminRoutes,
      settingsRoutes,
      newRoutes,
      accountRoutes,
      ...notFoundRoutes,
    ],
  },

  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
