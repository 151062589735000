import { ISystemSettings } from "@/interfaces/system";
import { apiUrl } from "@/consts";
import axios from "axios";

export const systemMetadataAPI = {}

export const systemCheckAPI = {
    ...systemMetadataAPI,

}

export const systemAPI = {
    ...systemCheckAPI,

    async readSystemSettings() {
        return axios.get<ISystemSettings>(
            `${apiUrl}/v1/system/settings`
        )
    },

    async updateSystemSettings(payload: ISystemSettings) {
        return axios.patch<ISystemSettings>(
            `${apiUrl}/v1/system/settings`,
            payload
        )
    }

}

export default systemAPI;