import api from "@/api";
import { extend } from "vee-validate";

extend("uniq_subject", {
  params: ["actual_subject"],
  validate: async (value, { actual_subject }: IVeeValidateParameter) => {
    if (value === actual_subject) {
      return true;
    }

    if (!value) {
      return false;
    }

    try {
      const response = await api.checkSubject(value);

      return response.status == 204;
    } catch (error) {
      return true;
    }
  },
  message: "{_field_} is already known",
});
