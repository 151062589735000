
export function arraysEqual(firstArray: any[], secondArray: any[]) {
    return firstArray.length === secondArray.length && firstArray.every((x, y) => x === secondArray[y])
}

export function objectsEqual(firstObject: any, secondObject: any) {
    const firstKeys = Object.keys(firstObject).sort();
    const secondKeys = Object.keys(secondObject).sort();

    if (firstKeys.length !== secondKeys.length) {
        return false;
    }

    return firstKeys.every(
        (k, v) => {
            const v1 = firstObject[k];
            const v2 = secondObject[secondKeys[v]];

            return v1 === v2
        }
    )
}

export function isUUID(data: string) {
    const regex = new RegExp('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$')

    return regex.test(data);
}