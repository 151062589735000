import { IMachineMinimal } from "@/interfaces/machine";

export enum SubjectSubmitFormatEnum {
  host = "host",
  adblock = "adblock",
  plain = "plain"
}

export enum SubjectTypeEnum {
  domain = "domain",
  url = "url",
  any = "any"
}


export interface ISubject {
  id: string;
  subject: string;
  status?: ISubjectStatus;
}

export interface ISubjectCreate {
  subject: string;
}

export interface ISubjectSubmit {
  subject_type: SubjectTypeEnum;
  input_format: SubjectSubmitFormatEnum;
  subjects: string[];
}

export interface ILatestSubjectStatus {
  status: string;
  status_source: string;
  tested_at: Date;
  id: string;
  submitter?: IMachineMinimal
}

export interface ISyntaxSubjectStatus {
  latest: ILatestSubjectStatus;
  frequent: string;
  recommended: string;
}

export interface IAvailabilitySubjectStatus {
  latest: ILatestSubjectStatus;
  frequent: string;
  recommended: string;
}

export interface IReputationSubjectStatus {
  latest: ILatestSubjectStatus;
  frequent: string;
  recommended: string;
}

export interface IWhoisSubjectStatus {
  expiration_date: Date;
  id: string;
  subject_id: string;
}

export interface ISubjectStatus {
  syntax: ISyntaxSubjectStatus;
  availability: IAvailabilitySubjectStatus;
  reputation: IReputationSubjectStatus;
  whois: IWhoisSubjectStatus;
}

export interface ISubjectInfo {
  subject: string;
  id: string;
  status: ISubjectStatus;
}

export interface ISubjectSearch {
  subject: string;
  approximate?: boolean;
}