import { AuthState } from "@/store/auth/state";
import { actions } from "@/store/auth/actions";
import { getters } from "@/store/auth/getters";
import { mutations } from "@/store/auth/mutations";

const defaultState: AuthState = {
  isLoggedIn: null,
  loginError: false,
  passwordRecoveryError: false,
  passwordResetError: false,
  loggedInUser: null,
  loggedInUserTokens: [],
  loggedInUserMachines: [],
  loggedInUserMachinesTotal: 0,
  loggedInUserScopes: null,
  loggedInUserContainers: [],
  loggedInUserContainersTotal: 0,
};

export const authModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters,
};
