import { extend } from "vee-validate";

extend("required_test_method", {
  params: ["value2", "value3"],
  validate: (value, { value2, value3 }: IVeeValidateParameter) => {
    if (!value && !value2 && !value3) {
      return false;
    }

    return true;
  },
  message: "At least one test method is required",
  computesRequired: true,
});
