import { ContainerState } from "@/store/container/state";
import { actions } from "@/store/container/actions";
import { getters } from "@/store/container/getters";
import { mutations } from "@/store/container/mutations";

const defaultState: ContainerState = {
  containers: [],
  containersTotal: 0,

  lContainer: null,

  lContainerExportRules: [],
  lContainerExportRulesTotal: 0,

  lContainerRemoteSubjects: [],
  lContainerRemoteSubjectsTotal: 0,

  lContainerAssociatedSubjects: [],
  lContainerAssociatedSubjectsTotal: 0,

  lContainerIgnoredSubjects: [],
  lContainerIgnoredSubjectsTotal: 0,

  lContainerStashedSubjects: [],
  lContainerStashedSubjectsTotal: 0,
}

export const containerModule = {
  // namespaced: true,
  state: defaultState,
  mutations,
  actions,
  getters
}