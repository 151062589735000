const accountRoutes = {
    path: ":username",
    component: () =>
        import(/* webpackChunkName: "account" */ "../views/account/StartView.vue"),
    children: [
        {
            path: "",
            name: "account",
            component: () =>
                import(/* webpackChunkName: "account-viewer" */ "../views/account/AccountView.vue"),
        },
        {
            path: ":container",
            name: "account-container",
            component: () =>
                import(/* webpackChunkName: "account-container-viewer" */ "../views/account/ContainerView.vue"),
        }
    ]
}

export default accountRoutes
