import { extend } from "vee-validate";
import { roleNameExists } from "@/system/role";

extend("uniq_role_name", {
  params: ["actual_name"],
  validate: async (value, { actual_name }: IVeeValidateParameter) => {
    if (value === actual_name) {
      return true;
    }

    if (!value) {
      return false;
    }

    return !await roleNameExists(value);
  },
  message: "{_field_} is already taken",
});
