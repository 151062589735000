import { IReservedUser, IReservedUserCreate } from "@/interfaces/reservedUsers";
import { commitAddNotification, commitRemoveNotification } from "@/store/main/mutations";
import { commitSetReservedUsers, commitSetReservedUsersTotal } from "@/store/reservedUser/mutations";

import { ActionContext } from "vuex";
import { ReservedUserState } from "@/store/reservedUser/state";
import { State } from "@/store/state";
import { SystemNotification } from "@/interfaces/system";
import api from "@/api";
import { dispatchCheckAPIError } from "@/store/main/actions";
import { getStoreAccessors } from "typesafe-vuex";

type ReservedUserContext = ActionContext<ReservedUserState, State>;

export const actions = {
  async actionGetReservedUsers(context: ReservedUserContext, { offset = 0, limit = 0 }) {
    try {
      const response = await api.getReservedUsers(
        offset, limit
      )

      if (response.data) {
        commitSetReservedUsers(context, response.data);
        commitSetReservedUsersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))
      } else {
        commitSetReservedUsers(context, []);
        commitSetReservedUsersTotal(context, 0);
      }
      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionSearchReservedUsers(context: ReservedUserContext, { searchQuery = "", approximate = false, offset = 0, limit = 100 }) {
    try {
      const response = await api.searchReservedUsers(
        { username: searchQuery, approximate: approximate },
        offset,
        limit
      );

      if (response.data) {
        commitSetReservedUsers(context, response.data);
        commitSetReservedUsersTotal(context, parseInt(response.headers["x-total"] || String(response.data.length)))
      } else {
        commitSetReservedUsers(context, []);
        commitSetReservedUsersTotal(context, 0);
      }
      return response.data
    } catch (error) {
      await dispatchCheckAPIError(context, error)
    }
    return null
  },

  async actionAddReservedUser(context: ReservedUserContext, reservedUser: IReservedUserCreate) {
    const loadingNotification: SystemNotification = {
      content: "Adding reservd user ...",
      color: "info",
      showProgress: true
    };

    commitAddNotification(context, loadingNotification);

    try {
      const response = await api.addReservedUser(
        reservedUser
      )

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully create the '${response.data.username}' reservation.`,
        color: "success"
      });

      return response.data
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
    return null
  },

  async actionDeleteReservedUser(context: ReservedUserContext, reservedUser: IReservedUser) {
    const loadingNotification: SystemNotification = {
      content: "Deleting reserved user ...",
      color: "info",
      showProgress: true
    };

    commitAddNotification(context, loadingNotification);

    try {
      await Promise.all([
        await api.deleteReservedUser(reservedUser.id),
        await new Promise((resolve) => setTimeout(() => resolve(true), 500)),
      ])

      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: `Successfully deleted the '${reservedUser.username}' reservation.`,
        color: "success"
      });

    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      await dispatchCheckAPIError(context, error);
    }
  }
}

const { dispatch } = getStoreAccessors<ReservedUserState | any, any>("");

export const dispatchGetReservedUsers = dispatch(actions.actionGetReservedUsers);
export const dispatchAddReservedUser = dispatch(actions.actionAddReservedUser);
export const dispatchDeleteReservedUser = dispatch(actions.actionDeleteReservedUser);
export const dispatchSearchReservedUsers = dispatch(actions.actionSearchReservedUsers);
